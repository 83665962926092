<ng-container *ngIf="typeAccount !== 'noAccount'; else notAttach">
  <app-board *ngIf="boardCards && accountVars && !loading; else loader"
    [boardCards]="filterBoardCardsByEtablissementsForFamilly()" [compact]="platformService.isSmall"
    [variables]="accountVars"></app-board>
</ng-container>


<ng-template #notAttach>
  <div class="fx-direction-row fx-center-center">
    <mat-icon color="warn" style="margin-right: 5px;">warning</mat-icon>
    <h3>{{'login.no_account_err_message'|trans}}</h3>
  </div>
</ng-template>


<ng-template #loader>
  <app-loader class="margin" message="Chargement du compte..."></app-loader>
</ng-template>
