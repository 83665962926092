import { Child } from '@app/models/child';
import { Injectable } from '@angular/core';
import { computeAge } from '@app/utils/date-functions';
import { BaseAdapterService } from './base-adapter.service';

@Injectable({ providedIn: 'root' })
export class ChildAdapter extends BaseAdapterService<Child> {
  adapt(item: any): Child {
    const child = item as Child;

    child.id = item.idEnfant;

    if (child.dateNaissance && typeof child.dateNaissance === 'string') {
      child.dateNaissance = new Date(child.dateNaissance + 'T00:00:00.000');
      // NB : above, we must add 'T00:00:00.000' to avoid date to be considered as UTC 
      // (Otherwise clients in other timezone that the server could see a wrong date)
      child.age = computeAge(child.dateNaissance);
    } else {
      child.age = { years: 0, months: 0 };
    }

    return child;
  }
}
