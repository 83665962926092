import { Component, OnDestroy, OnInit } from '@angular/core';
import { News } from '@app/models/news';
import { NewsService } from '@app/services/news.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FamilyService, PlatformService } from '@app/services';
import { Subject, of } from 'rxjs';
@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy {

  news: News[];
  newsFiltered: News[];
  destroy$ = new Subject();
  accountVars: any;

  constructor(
    private newsService: NewsService,
    public platformService: PlatformService,
    private familyService: FamilyService,
  ) {
  }

  ngOnInit() {
    this.familyService.currentFamilyChange$.pipe(
      takeUntil(this.destroy$),
      switchMap(fam => fam ? this.familyService.getFamilyVariables(fam.id) : of({}))
    ).subscribe((vars: any) => {
      this.accountVars = vars;
      this.loadNews();
    });
  }

  getThumbnail(aNews: News) {
    return aNews.content.substr(0, 100);
  }

  filterNews(event) {
    this.newsFiltered = event;
  }

  loadNews() {
    this.newsService.getAllNews('public').subscribe(news => {
      this.news = news.filter(news =>
        !news.isPrivate || !news.etablissementAccueil.length || news.accueilsIds?.some(id =>
          this.accountVars.etablissementAccueil?.some((accEtb) => accEtb.accueils.includes(id)))
      )
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
