import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  // Méthode pour stocker un item
  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Méthode pour récupérer un item
  getItem(key: string): any {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  }

  // Méthode pour supprimer un item
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Méthode pour vider tout le localStorage
  clearAll(): void {
    localStorage.clear();
  }
}
