import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormConfigService, FormHelperService, PermissionService, FamilyService } from '@app/services';
import { ExtendedFormlyFieldConfig, StepType } from '@app/forms/config/form-model';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { FormType } from '@app/models/global-config';
import { PreinscriptionService, AgrementMinMax } from '@app/services/preinscription.service';


@Component({
  selector: 'app-user-preinscription-edit',
  templateUrl: './preinscription-edit.component.html',
  styleUrls: ['./preinscription-edit.component.scss']
})
export class PreinscriptionEditComponent implements OnInit {

  id: number;
  step: string;
  readOnly: boolean;
  form: StepType[];
  data = {};
  // dataSouhaits = {};
  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-pre-inscription';
  isNewPreinscription: boolean;
  @Input() fromDemarche: boolean;
  @Output() save = new EventEmitter();

  // listIdEtabsSelected: number[] = [];
  // dateDebut: string;
  // dateFin: string;

  formTitle: string;

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private preinscriptionService: PreinscriptionService,
    private permService: PermissionService,
    private familyService: FamilyService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
  }

  ngOnInit() {
    if (!this.fromDemarche) {
      this.id = +this.route.snapshot.paramMap.get('id') !== 0 ? +this.route.snapshot.paramMap.get('id') : null;
      this.step = this.route.snapshot.paramMap.get('step');
      this.isNewPreinscription = this.route.snapshot.routeConfig.path === "add";

      if (this.isNewPreinscription) {
        this.formTitle = this.route.snapshot.data.title;
      }
    } else {
      this.isNewPreinscription = true;
    }

    forkJoin([
      this.preinscriptionService.getFormData(this.id, this.step),
      this.loadAgrements()
    ]).subscribe(([result, agrements]) => {

      if (result.config) {
        this.form = this.formConfigService.getFormView(result.config).filter(f => f.enabled);
      }

      this.data = !result.data ? { modeCreation: true } : result.data;

      this.readOnly = (!this.permService.hasPermission('preinscription_edit') || !this.familyService.currentFamily.active) &&
        !this.isNewPreinscription ||
        (!this.isNewPreinscription && result.decision !== "En attente" && result.decision !== "");

      // this.formTitle = this.readOnly ? 'Voir pré-inscription' : 'Modifier pré-inscription';

      if (agrements && agrements.length) {
        this.setMinMaxHoursProgrammation(agrements);
      }

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.formViewer.handleReturnProgramOnDisplay(result)
      })

      this.isLoading = false;

    });
  }

  loadAgrements(): Observable<AgrementMinMax[]> {
    if (this.id && !this.isNewPreinscription && this.step === "Programmation") {
      return this.preinscriptionService.getFormData(this.id, "accueil").pipe(
        map((res: any) => res.data),
        switchMap(dataSouhaits => this.getAgrements(dataSouhaits))
      )
    } else {
      return of(null)
    }
  }

  getAgrements(data): Observable<AgrementMinMax[]> {
    if (!!data) {
      let dateDebut = data.Bloc_dates_inscription?.souhaitDateDebut ? moment(data.Bloc_dates_inscription?.souhaitDateDebut).format('L') : "";
      let dateFin = data.Bloc_dates_inscription?.souhaitDateFin ? moment(data.Bloc_dates_inscription?.souhaitDateFin).format('L') : "";
      let listIdEtabs: number[] = data.Bloc_souhaits_accueil?.map(souhait => souhait.etablissement);
      listIdEtabs = [...new Set(listIdEtabs)];
      if (listIdEtabs.length) {
        return (this.preinscriptionService.getAgrementsByIdEtabs(listIdEtabs, dateDebut, dateFin))
      }
    }
    return of(null)
  }

  onStepChange(step) {
    if (this.form[step]?.stepName === 'Programmation') {
      this.getAgrements(this.data).subscribe(agrements => {
        this.setMinMaxHoursProgrammation(agrements)
      })
    }
  }

  setMinMaxHoursProgrammation(listAgrementsbyDay: AgrementMinMax[]) {
    let fieldProgrammation = this.getProgrammationField();
    listAgrementsbyDay.forEach(agrement => {
      for (let fieldDayDefault of fieldProgrammation.defaultValue) {
        if (fieldDayDefault.key.includes(agrement.numJour.toString())) {
          fieldDayDefault.templateOptions = fieldDayDefault.templateOptions || {}
          fieldDayDefault.templateOptions.maxHour = agrement.maxHeure
          fieldDayDefault.templateOptions.minHour = agrement.minHeure

          fieldDayDefault = { ...fieldDayDefault }
        }
      }
    });
  }

  getProgrammationField(): ExtendedFormlyFieldConfig {
    const programmationStep = this.form.filter(step => step.stepName === 'Programmation')[0]
    if (!!programmationStep) {
      return this.formConfigService.findFieldByName(programmationStep.fields, "jour_programmation");
    }
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');

    if (data["deja_ne"] === 'Oui') {
      data["dateAccouchement"] = '';
    } else {
      data["dateNaissance"] = '';
    }

    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }
    this.isLoading = true;
    const saveMethod = (this.id) ? this.preinscriptionService.update(data, this.id, this.step) : this.preinscriptionService.create(data);
    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée', result.messages);
      if (this.fromDemarche) {
        let preInscriptionData = { id: result.id, nom: result.nom, prenom: result.prenom, type: 'Preinscription' };
        this.save.emit(preInscriptionData);
      } else {
        this.location.back();
      }
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })
    });

  }


}
