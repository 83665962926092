<div *ngIf="!loading; else loader" class="conteneur">
  <ng-container *ngFor="let news of newsList">
    <mat-card class="card fx-direction-col fx-between-center">
      <mat-card-header>
        <mat-card-title class="title">{{ news.title }}</mat-card-title>
      </mat-card-header>
      <img mat-card-image [src]="news.img" alt="">
      <mat-card-content class="contenu">
        <p>{{ news.subTitle }}</p>
      </mat-card-content>

      <mat-card-actions class="action-buttons">
        <button mat-stroked-button color="primary" routerLink="/news/{{ news.id }}">
          Lire l'actualité
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>
</div>

<ng-template #loader>
  <app-loader [message]="'Chargement...'" class="margin"></app-loader>
</ng-template>
