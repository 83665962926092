import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormConfigService, FormHelperService, PermissionService, FamilyService, UserService, SnackbarService } from '@app/services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StepType } from '@app/forms/config/form-model';
import { forkJoin, of } from 'rxjs';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { switchMap } from 'rxjs/operators';
import { AdresseUpdateService } from '@app/services/adresse-update.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { addressToUpdate } from '@app/models/address';
import { FormType } from '@app/models/global-config';

@Component({
  selector: 'app-conjoint-edit',
  templateUrl: './conjoint-edit.component.html',
  styleUrls: ['./conjoint-edit.component.scss']
})
export class ConjointEditComponent implements OnInit {

  idConjoint: number;
  idUser: number;
  idFamille: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  data: any;
  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-adulte';

  formTitle: string;

  dataUpdateAddress: addressToUpdate;
  addressIsUpdate: boolean;
  updateFamilyChecked: string;
  updateUserChecked: string;
  manyAddressesDisplay: boolean;
  foyerData: any;
  userData: any;

  @Input() fromDemarche: boolean;
  @Input() demarcheTitle: string;
  @Output() onValidate = new EventEmitter<any>();
  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;
  @ViewChild('updateAddressBottomSheet', { static: true }) updateAddressBottomSheet: TemplateRef<any>;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private familyService: FamilyService,
    private userService: UserService,
    private route: ActivatedRoute,
    private location: Location,
    private permService: PermissionService,
    public bottomSheet: MatBottomSheet,
    private addressUpdateService: AdresseUpdateService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit() {
    this.step = this.route.snapshot.paramMap.get('step');
    this.idFamille = this.familyService.currentFamily.id;
    this.idUser = this.userService.currentUser.id;

    this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(f => this.familyService.getFamilyData(f.id))
    ).subscribe((familyData: any) => {
      this.idConjoint = familyData.conjoint ? familyData.conjoint.idAdulte : null;
      this.formTitle = familyData.conjoint ? `${familyData.conjoint.prenom} ${familyData.conjoint.nom}` : 'Mon[Ma] conjoint[e]';

      if (this.fromDemarche) {
        this.formTitle = this.demarcheTitle;
      }

      this.readOnly = this.idConjoint && !this.permService.hasPermission('foyer_edit') || !this.familyService.currentFamily.active;

      this.familyService.getConjointFormData(this.step).subscribe((result: any) => {

        if (result.config) {
          this.form = this.formConfigService.getFormView(result.config).filter(f => f.enabled);
        }

        this.data = !result.data ? { modeCreation: true } : result.data;

        setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
          this.formViewer.handleReturnProgramOnDisplay(result)
        })

        this.isLoading = false;

      }, err => this.snackbarService.error('Une erreur est survenue lors du chargement'));

    });


  }

  checkAddress() {
    this.addressUpdateService.checkAddress('conjoint').subscribe((data: addressToUpdate) => {
      this.dataUpdateAddress = data;
      if ((data.userAddress && !data.userAddressIsSameConjointAddress) ||
        (data.familyAddress && !data.familyAddressIsSameConjointAddress)) {

        this.userData = this.addressUpdateService.extractDataWithoutAdress(data.user);
        this.foyerData = this.addressUpdateService.extractDataWithoutAdress(data.famille);

        this.bottomSheet.open(this.updateAddressBottomSheet);

        this.manyAddressesDisplay = this.addressUpdateService.manyAddressesDisplay(
          data.familyAddress,
          data.userAddress,
          data.familyAddressIsSameConjointAddress,
          data.userAddressIsSameConjointAddress)
      }
    });
  }

  validUpdate() {
    if (this.updateFamilyChecked === 'oui' && (this.updateUserChecked === 'non' || !this.updateUserChecked)) {
      this.addressUpdateService.updateAddressFoyer('conjoint', this.dataUpdateAddress);
    }

    if ((this.updateFamilyChecked === 'non' || !this.updateFamilyChecked) && this.updateUserChecked === 'oui') {
      this.addressUpdateService.updateAddressUser('conjoint', this.dataUpdateAddress);
    }

    if (this.updateFamilyChecked === 'oui' && this.updateUserChecked === 'oui') {
      this.addressUpdateService.updateAddresses('conjoint', this.dataUpdateAddress);
    }
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }
    this.isLoading = true;
    const saveMethod = (this.idConjoint) ? this.familyService.updateConjoint(data, this.step) : this.familyService.createConjoint(data);
    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée', result.messages);
      if (this.idConjoint) {
        if (this.step === "coordinates") {
          this.checkAddress();
        }
      }
      if (!this.fromDemarche) {
        this.familyService.idConjointCreate.next(result.idAdulte)
        this.location.back();
      } else {
        this.onValidate.emit({ id: this.idConjoint, type: 'Adulte' });
        this.isLoading = false;
      }
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })

    });
  }

}
