<app-loader *ngIf="loading" [message]="'Chargement des données...'" class="margin"></app-loader>

<div class="conteneur" *ngIf="!loading;">

  <div class="fx-direction-col">

    <mat-card *ngFor="let edition of editions" class="card">
      <mat-card-header class="card-header fx-direction-row fx-between-center">
        <mat-card-title class="fx-direction-row fx-start-center">
          <ng-container *ngTemplateOutlet="showIcon; context: {data: edition}"></ng-container>
          <span>{{ edition.titre }}</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-left: 20px;">
        <p *ngIf="edition.description && edition.description!==''" [innerHTML]="edition.description | safe"
          class="description"></p>

        <mat-list>
          <ng-container *ngFor="let entity of edition.entites; let i = index;">
            <mat-divider></mat-divider>
            <mat-list-item>
              <h4 matLine>{{entity.label}} </h4>

              <button mat-mini-fab color="primary" [matMenuTriggerFor]="menuEdition"
                *ngIf="! (edition.loadingPdfDownload && edition.loadingPdfDownload[entity.id])">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menuEdition="matMenu">
                <button mat-menu-item (click)="dowloadPDF('preview',edition, entity)">
                  <mat-icon color="primary">visibility</mat-icon>
                  <span>Aperçu</span>
                </button>
                <button mat-menu-item color="primary" (click)="dowloadPDF('download', edition, entity)">
                  <mat-icon color="primary">get_app</mat-icon>
                  <span>Télécharger</span>
                </button>
              </mat-menu>

              <div *ngIf="edition.loadingPdfDownload && edition.loadingPdfDownload[entity.id]"
                class="download-progress">
                <mat-progress-spinner diameter="40"
                  [mode]="edition.loadingPdfDownload[entity.id].progress === 0 ? 'indeterminate' : 'determinate'"
                  [value]="edition.loadingPdfDownload[entity.id].progress">
                </mat-progress-spinner>
                <span>{{ edition.loadingPdfDownload[entity.id].progress }}%</span>
              </div>

              <div matLine *ngIf="entity.labelDetail && entity.labelDetail!==''" [innerHTML]="entity.labelDetail | safe"
                class="label-detail"></div>

            </mat-list-item>

          </ng-container>

        </mat-list>

      </mat-card-content>
    </mat-card>

  </div>

  <div *ngIf="editions.length===0">
    <h2 style="text-align: center;">Aucune édition disponible.</h2>
  </div>
</div>

<ng-template #showIcon let-data="data">
  <mat-icon *ngIf="data?.icon && !data.iconDomino && !data.iconFontawesome" class="main-icon">{{data.icon}}
  </mat-icon>
  <app-icon *ngIf="data.iconFontawesome || data.iconDomino"
    [icon]="data.iconFontawesome ? data.iconFontawesome : data.iconDomino"
    [type]="data.iconDomino ? 'do' : (!data.typeIcon && data.iconFontawesome) ? 'fa' : data.typeIcon" [size]="24"
    style="margin-right: 12px;">
  </app-icon>
</ng-template>



<ng-template #pdfPreviewDialog>

  <mat-toolbar mat-dialog-title color="primary">
    <button class="close-dialog" mat-icon-button matTooltip="Fermer" matDialogClose>
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">Aperçu</span>
  </mat-toolbar>

  <mat-dialog-content class="preview-container thin-scrollbar">
    <app-loader *ngIf="!pdfPreviewLoaded" class="margin"></app-loader>
    <pdf-viewer [src]="pdfPreviewBuffer" [render-text]="false" class="pdf-viewer" [original-size]="false"
      [autoresize]="true" (after-load-complete)="callBackPdfLoad($event)"></pdf-viewer>
  </mat-dialog-content>

</ng-template>
