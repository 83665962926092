import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-new-password-type',
  template: `
  <app-new-password
   [username]="username"
   (valueChange)="onValueChange($event)"
   (statusChange)="onStatusChange($event)"
  ></app-new-password>`
})
export class NewPasswordTypeComponent extends FieldType implements OnInit {

  username: string;

  ngOnInit(): void {
    this.form.valueChanges.subscribe(formValue => {
      if (!!formValue['email']) {
        this.username = formValue['email']
      }
    })
  }

  onValueChange(value: string) {
    this.model[this.key as string] = value;
    this.form.get(this.key as string).setValue(value);
  }

  onStatusChange(status: string) {
    this.form.setErrors(status === 'VALID' ? null : { invalid: true });
  }
}
