import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PlatformService, SnackbarService, UserService } from '@app/services';
import { EnseignantService } from '@app/services/enseignant.service';
import { CalendarOptions, DatesSetArg, DayCellContentArg, EventApi, EventContentArg, EventInput } from '@fullcalendar/core';
import frLocale from '@fullcalendar/core/locales/fr';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { debounceTime, startWith, takeUntil, tap } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from '@app/services/planning.service';
import { FAKE_JOURCOURS } from '@app/services/enseignant.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { PeriodeService } from '@app/services/periode.service';
import { JourCours, PointageEnseignantConfig, PointageEnseignantPlanning } from '@app/models/pointage-enseignant';
import { Router } from '@angular/router';
import { StorageService } from '@app/services/storage.service';
import { Cours } from '@app/models/lasido';
import tippy from 'tippy.js';
import { MatDatepicker } from '@angular/material/datepicker';

// Constantes par défaut pour FullCalendar
const DEFAULT_CALENDAR_OPTIONS: CalendarOptions = {
  locale: frLocale,
  plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
  footerToolbar: {},
  displayEventEnd: true,
  eventDisplay: 'block',
  aspectRatio: 2,
  weekNumberCalculation: 'ISO',
  contentHeight: 'auto',
  height: 'auto',
  views: {
    listDay: { buttonText: 'Jour' },
    listWeek: { buttonText: 'Semaine' },
    listMonth: { buttonText: 'Mois' },
    dayGrid: {},
    week: {}
  }
};

@Component({
  selector: 'app-enseignant-planning',
  templateUrl: './enseignant-planning.component.html',
  styleUrls: ['./enseignant-planning.component.scss']
})
export class EnseignantPlanningComponent implements OnInit {

  @Input() config: PointageEnseignantConfig;
  @Input() coursList: Cours[];
  @Input() isListView: boolean;
  @Input() preview: boolean;
  @Input() idEnseignant: number;
  @ViewChild('calendar') calendar: FullCalendarComponent;
  @ViewChild('calendarWrapper') calendarWrapper: ElementRef;
  @ViewChild('picker') picker: MatDatepicker<Date>;

  // Observables & Data
  isSmallScreen: boolean;
  isVerySmallScreen: boolean;
  isLargeScreen: boolean;
  loading = true;
  feries: { date: string, detail?: string }[] = [];
  calendarOptions: CalendarOptions = { ...DEFAULT_CALENDAR_OPTIONS };
  planningData: PointageEnseignantPlanning = { events: [], cours: [] };
  loaders: Observable<any>[] = [];
  accentColor: string;

  private onDestroy$ = new Subject<void>();
  private isFirstChange = true;

  constructor(
    private enseignantService: EnseignantService,
    private platformService: PlatformService,
    private periodeService: PeriodeService,
    private router: Router,
    private storageService: StorageService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.idEnseignant = this.userService.currentUser.idEnseignant;
    this.loaders = [this.periodeService.getJoursFeries().pipe(tap(feries => this.feries = feries))];

    if (!this.preview) {
      this.loaders.push(this.getPlanningData());
    } else {
      this.planningData.cours.push(...FAKE_JOURCOURS);
    }

    forkJoin(this.loaders).subscribe(() => {
      this.configureCalendar();
      this.initResponsiveCalendar();
      this.loading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isFirstChange && changes['isListView']) {
      this.toggleView(this.isListView)
    }
    this.isFirstChange = false; // Pour éviter le déclenchement lors du premier chargement
  }

  /**
   * Configure les options du calendrier FullCalendar en fonction des données récupérées.
   */
  configureCalendar(): void {
    this.calendarOptions.initialView = !this.preview ? this.storageService.getItem('calendarView') || 'dayGridMonth' : 'dayGridMonth';
    this.calendarOptions.initialDate = !this.preview ? this.storageService.getItem('selectedDate') || moment().format(DATE_FORMAT) : moment().format(DATE_FORMAT);
    this.calendarOptions.events = this.generateEvents(this.planningData.cours);
    this.calendarOptions.eventDidMount = (info: any) => {
      if (!this.isListView) {
        tippy(info.el, {
          content: info.event.extendedProps.jourCours.libelle,
          theme: 'translucent'
        });
      }
    }

    // Attachement des callbacks
    this.calendarOptions.eventClick = this.onEventClick.bind(this);
    this.calendarOptions.datesSet = this.onDateSet.bind(this);
    this.calendarOptions.dayCellClassNames = this.getDayClassNames.bind(this);
    this.calendarOptions.dayCellContent = this.getDayContent.bind(this);
    this.calendarOptions.eventClassNames = this.getEventClassNames.bind(this);
    // this.calendarOptions.eventContent = this.getEventContent.bind(this);

    // Configuration spécifique
    this.calendarOptions.weekends = !!this.config.calendar.weekends;
    this.calendarOptions.fixedWeekCount = this.config.calendar.fixedWeekCount;
    this.calendarOptions.showNonCurrentDates = this.config.calendar.showNonCurrentDates;

    const maxEvents = this.config.calendar.eventLimit;
    if (maxEvents) {
      this.calendarOptions.dayMaxEvents = maxEvents === -1 ? true : maxEvents;
    }

    this.configureToolbar();
  }

  /**
   * Génère et configure les boutons du calendrier selon la vue.
   */
  configureToolbar(): void {
    const rightSection = this.isListView && !this.preview ? 'listMonth,listWeek,listDay' : 'dayGridMonth,timeGridWeek,timeGridDay';
    this.calendarOptions.headerToolbar = {
      left: 'prev,next customDatePickerButton,today',
      center: 'title',
      right: rightSection
    };

    this.calendarOptions.customButtons = {
      customDatePickerButton: {
        text: '',
        click: () => this.openDatePicker(),  // Méthode pour ouvrir le datepicker
      }
    }

    setTimeout(() => {
      const datePickerButton = document.querySelector('.fc-customDatePickerButton-button');
      if (datePickerButton) {
        datePickerButton.innerHTML = '<span class="material-icons">event</span>';
      }
    });
  }

  openDatePicker() {
    this.picker.open();
  }

  onDateSelected(selectedDate: Moment | null) {
    if (selectedDate) {
      const formattedDate = selectedDate.toDate();
      // Naviguer vers la date dans le calendrier FullCalendar
      const calendarApi = this.calendar.getApi();
      calendarApi.gotoDate(formattedDate);
    }
  }

  /**
   * Initialise les actions de redimensionnement pour ajuster le ratio du calendrier.
   */
  initResponsiveCalendar(): void {
    this.platformService.mainWidth$.pipe(
      startWith(0),
      debounceTime(500),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.updateResponsiveSettings();
      this.calendar?.getApi()?.updateSize();
    });
  }

  /**
   * Adapte l'affichage du calendrier en fonction de la largeur de l'écran.
   */
  updateResponsiveSettings(): void {
    const width = this.getCalendarWidth();
    if (!width) return;

    this.isSmallScreen = width < 620;
    this.isVerySmallScreen = width < 400;
    this.isLargeScreen = width > 860;

    const weekDayFormat = this.isSmallScreen ? 'short' : 'long';
    this.calendarOptions.views.dayGrid.dayHeaderFormat = { weekday: weekDayFormat };
    this.calendarOptions.views.week.dayHeaderFormat = { weekday: weekDayFormat, day: 'numeric' };

    setTimeout(() => {
      this.calendarOptions.aspectRatio = this.getAspectRatioForWidth(width);

      const datePickerButton = document.querySelector('.fc-customDatePickerButton-button');
      if (datePickerButton) {
        datePickerButton.innerHTML = '<span class="material-icons">event</span>';
      }
    });

    this.refreshButtons(this.isListView);
  }

  getDayClassNames(data: DayCellContentArg) {
    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);

    const classes = [];

    // Désactive les jours non éditables
    if (!this.enseignantService.isEditableDate(dateStr, this.config)) {
      classes.push('fc-day-disabled');
    }

    return classes;
  }

  getDayContent(data: DayCellContentArg) {
    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);
    const domNodes: HTMLElement[] = [];
    const ferie = this.feries.find(f => f.date === dateStr);

    const cellHeader = document.createElement('div');
    cellHeader.classList.add('day-cell-header');

    if (ferie && data.view.type === 'dayGridMonth') {
      const ferieEl = document.createElement('span');
      ferieEl.innerText = 'Férié';
      ferieEl.classList.add('spacer', 'ferie');
      cellHeader.appendChild(ferieEl);
    } else {
      const spacer = document.createElement('span');
      spacer.classList.add('spacer');
      cellHeader.appendChild(spacer);
    }

    const dayNumber = document.createElement('span');
    dayNumber.classList.add('day-number');
    dayNumber.innerText = '' + data.date.getDate();
    cellHeader.appendChild(dayNumber);

    domNodes.push(cellHeader);

    return { domNodes };
  }

  getEventClassNames(data: EventContentArg) {
    return ['no-underline-animation', 'event-times'];
  }

  toggleView(isListView: boolean): void {
    const calendarApi = this.calendar?.getApi();

    // Changer la vue selon le mode
    if (isListView) {
      calendarApi.changeView('listMonth');
    } else {
      calendarApi.changeView('dayGridMonth');
    }

    // Mettre à jour les boutons d'outils
    this.refreshButtons(isListView);
    calendarApi.render();
  }

  refreshButtons(isListView: boolean): void {
    // Met à jour la barre d'outils en fonction de la taille de l'écran et de la vue actuelle
    if (this.isSmallScreen || this.isVerySmallScreen) {
      this.calendarOptions.headerToolbar = {
        left: 'prev',
        center: 'title',
        right: 'next'
      };
      this.calendarOptions.footerToolbar = {
        left: 'customDatePickerButton,today',
        center: '',
        right: isListView && !this.preview ? 'listMonth,listWeek,listDay' : 'dayGridMonth,timeGridWeek,timeGridDay' // Déplace les boutons en bas à droite
      };
    } else {
      this.calendarOptions.headerToolbar = {
        left: 'prev,next customDatePickerButton,today',
        center: 'title',
        right: isListView && !this.preview ? 'listMonth,listWeek,listDay' : 'dayGridMonth,timeGridWeek,timeGridDay'
      };
      this.calendarOptions.footerToolbar = { left: '', center: '', right: '' };
    }
  }

  /**
   * Génère la liste des événements à afficher dans le calendrier.
   */
  generateEvents(cours: JourCours[]): EventInput[] {
    return cours.map(cours => this.buildEventFromCours(cours));
  }

  /**
   * Crée un événement à partir d'un cours.
   */
  buildEventFromCours(cours: JourCours): EventInput {
    return {
      start: `${cours.date}T${cours.heureDebut}`,
      end: `${cours.date}T${cours.heureFin}`,
      title: cours.libelle,
      color: this.accentColor,
      jourCours: cours
    };
  }

  getCoursTitle(cours: JourCours): string {
    const foundCours = this.coursList?.find(c => c.idCours === cours.idCours);
    return foundCours ? foundCours.libelle : cours.libelle;
  }

  getPlanningData(): Observable<any> {
    return this.enseignantService.getCoursSessionList(this.idEnseignant).pipe(
      tap((jourCours: JourCours[]) => {
        jourCours.forEach(jc => jc.libelle = this.getCoursTitle(jc))
        this.planningData.cours = jourCours;
      })
    );
  }

  onEventClick(eventInfo: { event: EventApi }): void {
    let jourCours: JourCours = eventInfo.event.extendedProps.jourCours;
    jourCours.libelle = this.getCoursTitle(jourCours);
    if (!this.preview) {
      this.storageService.setItem('selectedDate', jourCours.date);
      this.router.navigate([`account/enseignant-planning/students-pointage/${jourCours.idJourCours}`]);
    }
  }

  onDateSet(arg: DatesSetArg): void {
    if (!this.preview) {
      this.storageService.setItem('calendarView', arg.view.type);
    }
  }

  // Méthodes utilitaires
  getCalendarWidth(): number {
    return this.calendarWrapper ?
      (this.calendarWrapper.nativeElement as HTMLElement).offsetWidth :
      this.platformService.mainWidth();
  }

  getAspectRatioForWidth(width: number): number {
    return width <= 600 ? 0.8 : (width <= 900 ? 1.6 : 2);
  }
}
