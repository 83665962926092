<ng-template appTopbarActions>
  <button mat-raised-button color="accent" style="margin-right: 5px;" (click)="addAvailability()"
    [disabled]="readOnly || saving || loading">
    <mat-icon matTooltip="Ajouter une disponibilité">add_box</mat-icon>
  </button>
</ng-template>

<div [ngClass]="{'availability-list': !platformService.isMobile, 'availability-list-mobile': platformService.isMobile}">

  <app-loader-overlay *ngIf="loading || saving"></app-loader-overlay>

  <div class="mat-elevation-z4">
    <table #table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matSortDirection="asc">

      <ng-container matColumnDef="agrementSecondaire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="agrementSecondaire">Agrément secondaire</th>
        <td mat-cell *matCellDef="let availability">
          {{availability.agrementSecondaire?.designation}}
        </td>
      </ng-container>

      <ng-container matColumnDef="capacite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="capacite">Places</th>
        <td mat-cell *matCellDef="let availability"> {{availability.capacite}} </td>
      </ng-container>

      <ng-container matColumnDef="dateDebut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dateDebut">Début</th>
        <td mat-cell *matCellDef="let availability">
          {{availability.dateDebut | date:'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="dateFin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="dateFin">Fin</th>
        <td mat-cell *matCellDef="let availability"> {{availability.dateFin | date:'dd/MM/yyyy'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let availability">
          <button mat-icon-button color="warn" (click)="deleteAvailability(availability); $event.stopPropagation()"
            matTooltip="Supprimer" [disabled]="readOnly">
            <mat-icon *ngIf="availability.idDisponibiliteManuelle !== deleting">delete</mat-icon>
            <mat-progress-spinner *ngIf="availability.idDisponibiliteManuelle === deleting" mode="indeterminate"
              strokeWidth="2" diameter="24" color="accent" class="icon-loader">
            </mat-progress-spinner>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let availability" [attr.colspan]="displayedColumns.length">
          <div class="availability-detail"
            [@detailExpand]="availability == expandedAvailability ? 'expanded' : 'collapsed'"
            [ngStyle]="{'width': platformService.isMobile ? '100%' : '60%'}">

            <div style="margin: 10px 0;" class="fx-direction-col fx-start-stretch">
              <div class="fx-direction-row fx-wrap fx-center-center">
                <mat-form-field class="no-hint responsive-field">
                  <mat-label>Agrément secondaire</mat-label>
                  <mat-select [compareWith]="compareWithFn" [(ngModel)]="availability.agrementSecondaire"
                    [disabled]="readOnly">
                    <mat-option style="font-style: italic;">--Aucun--</mat-option>
                    <mat-option *ngFor="let agrementSecondaire of listAgrementsSecondaires"
                      [value]="agrementSecondaire">
                      {{agrementSecondaire.designation}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="no-hint responsive-field">
                  <mat-label>Nombre de place</mat-label>
                  <input matInput type="number" min="0" [(ngModel)]="availability.capacite" [disabled]="readOnly">
                </mat-form-field>
              </div>

              <div class="fx-direction-row fx-wrap fx-center-center">
                <mat-form-field class="no-hint responsive-field">
                  <mat-label>A partir du : </mat-label>
                  <input matInput [(ngModel)]="availability.dateDebut" [matDatepicker]="publishFromDatePicker"
                    [max]="availability.dateFin" [disabled]="readOnly">
                  <mat-datepicker-toggle matSuffix [for]="publishFromDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #publishFromDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="no-hint responsive-field">
                  <mat-label>Jusqu'au : </mat-label>
                  <input matInput [(ngModel)]="availability.dateFin" [matDatepicker]="publishUntilDatePicker"
                    [min]="availability.dateDebut" [disabled]="readOnly">
                  <mat-datepicker-toggle matSuffix [for]="publishUntilDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #publishUntilDatePicker></mat-datepicker>
                </mat-form-field>
              </div>

              <mat-form-field class="no-hint">
                <mat-label>Commentaire</mat-label>
                <textarea matInput matTextareaAutosize matAutosizeMinRows=3 [(ngModel)]="availability.commentaire"
                  [disabled]="readOnly"></textarea>
              </mat-form-field>
            </div>


          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="availability-row"
        [class.expanded-row]="expandedAvailability === row"
        (click)="expandedAvailability = expandedAvailability === row ? null : row"
        [@highlightRowsAnimation]="row.highlightRowsAnimation" [@rowEnterLeave]="row.rowEnterLeaveAnimation">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
  </div>
</div>

<div class="save-content fx-direction-row fx-center-center">
  <button mat-raised-button color="accent" (click)="save()" [disabled]="readOnly || loading || saving">
    <mat-icon matTooltip="Enregistrer" *ngIf="!saving">check</mat-icon>
    <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent"
      class="icon-loader">
    </mat-progress-spinner>
    Valider
  </button>

</div>
