import { Injectable, Component } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';
import { WaitingRoomService, ConfirmType } from '@app/services';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class WaitingRoomGuard implements CanDeactivate<Component> {

  constructor(
    private waitingRoomService: WaitingRoomService,
    private location: Location
  ) { }

  canDeactivate(component: Component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {

    // console.log('waitingRoomService / canDeactivate', component, 'currentRoute', currentRoute, 'currentState', currentState, 'nextState', nextState)

    if (!this.waitingRoomService.inRestrictedRoom$.value && !this.waitingRoomService.inWaitingRoom$.value) { // no waiting room
      return of(true);
    } else if (nextState.url.startsWith('/account/reservations')) {  // don't want to quit réservation area
      return of(true)
    } else { // here we want to inform user that he is in restricted room

      let typeConfirm: ConfirmType

      if (this.waitingRoomService.inRestrictedRoom$.value) {
        console.log("Want to quit restricted area ?")
        typeConfirm = 'quitRestrictedRoom'
      } else {
        console.log("Want to quit Waiting room ?")
        typeConfirm = 'quitWaitingRoom'
      }

      return this.waitingRoomService.confirm(typeConfirm).pipe(
        tap(confirmValue => {
          if (typeConfirm === 'quitRestrictedRoom' && confirmValue === 2) {
            this.waitingRoomService.quitWaitingRoom('Sortie de l\'utilisateur depuis la salle de réservation', true, false)
          }
        }),
        map(confirmValue => confirmValue > 0),
        tap(confirmed => {
          if (typeConfirm === 'quitWaitingRoom') {
            if (confirmed) {

              this.waitingRoomService.quitWaitingRoom('Sortie de l\'utilisateur depuis la salle d\'attente', true, false)
            } else {
              this.waitingRoomService.openWaitingRoom()
              this.location.forward()
            }
          }

        })
      )

    }
  }

}
