import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DemarcheStep } from '@app/models/demarche';

@Component({
  selector: 'app-demarche-question-step',
  templateUrl: './demarche-question-step.component.html',
  styleUrls: ['./demarche-question-step.component.scss']
})
export class DemarcheQuestionStepComponent implements OnInit {

  @Input() step: DemarcheStep;
  @Output() validate = new EventEmitter<number>();
  responseIdValue: number;

  constructor() { }

  ngOnInit(): void {
  }

  onValidate() {
    this.validate.emit(this.responseIdValue);
  }
}
