<div class="conteneur" *ngIf="!loading; else loader">
  <mat-card>
    <mat-card-header class="card-header">
      <mat-card-title
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; align-content: center">
        {{jourCours.date | date:'fullDate' | titlecase}} de {{jourCours.heureDebut | slice:0:5}} à
        {{jourCours.heureFin | slice:0:5}}
      </mat-card-title>
      <mat-card-subtitle
        style="display: flex; justify-content: center; align-items: center; align-content: center; font-weight: 500;">
        <span>{{ jourCours.libelle }}</span>
      </mat-card-subtitle>
    </mat-card-header>

    <div class="student-list">
      <div *ngIf="!studentsList.length" style="font-size: 1.5rem; font-weight: 500; text-align: center;">
        {{ 'planning_enseignant.pointage_eleves.no_eleves' | trans}}
      </div>
      <ul class="student-list-items">
        <li *ngFor="let student of studentsList" class="student-item border"
          [ngClass]="{'reelle': !student?.presenceAbsence?.previsionnel && student.presenceAbsence}"
          [style.border-color]="(getRubriqueColor(student.idInscription) | customColor) | safe:'style'">
          <div style="display: flex; justify-content: space-between; align-items: center; width:100%">
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <i class="material-icons student-icon"
                [style.color]="(getRubriqueColor(student.idInscription) | customColor) | safe:'style'">
                person
              </i>
              <div class="student-name">
                {{ student.nomReferent }}
              </div>
              <span *ngIf="student.heureDebut && student.heureFin"
                style="font-style: italic; font-size: small; margin-left: 5px;">({{student.heureDebut| slice:0:5}} -
                {{student.heureFin| slice:0:5}})</span>
            </div>

            <app-icon type="do" icon="previsionnel" *ngIf="student.presenceAbsenceReel && student.presenceAbsencePrev"
              [matMenuTriggerFor]="menu" matTooltip="Prévisionnel" style="cursor: pointer;"></app-icon>

            <mat-menu #menu="matMenu">
              <div *ngIf="student.presenceAbsencePrev" style="padding: 5px; min-width: 200px;">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <i class="material-icons student-icon"
                      [style.color]="(student.presenceAbsencePrev.rubriqueColor | customColor) | safe:'style'">
                      person
                    </i>
                    <div class="student-name">
                      {{ student.nomReferent }}
                    </div>
                    <span *ngIf="student.heureDebut && student.heureFin"
                      style="font-style: italic; font-size: small; margin-left: 5px;">({{student.heureDebut| slice:0:5}}
                      -
                      {{student.heureFin| slice:0:5}})</span>
                  </div>
                </div>

                <mat-divider style="margin-top: 10px;"></mat-divider>
                <div style="display: flex; justify-content: center; align-items: center; margin-top: 10px;">
                  <app-icon type="do" icon="rubrique-planning"
                    [style.color]="(student.presenceAbsencePrev.rubriqueColor | customColor) | safe:'style'"
                    style="margin-right: 5px;">
                  </app-icon>
                  {{student.presenceAbsencePrev.rubriqueName}}
                </div>
              </div>
            </mat-menu>
          </div>
          <div class="student-options">
            <mat-radio-group aria-label="Rubrique" class="radio-group"
              [(ngModel)]="selectedRubriques[student.idInscription]" [disabled]="!isEditable || student.isNotEditable">
              <mat-radio-button *ngFor="let rubrique of rubriques" [value]="rubrique.id">
                <app-icon type="do" icon="rubrique-planning"
                  [style.color]="((rubrique.couleurPortail|customColor) || rubrique.couleur)|safe:'style'">
                </app-icon> {{ rubrique.name }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </li>
      </ul>
    </div>

    <mat-card-actions style="display: flex; justify-content: space-between; align-items: center;">
      <button mat-raised-button (click)="onClickBack()" color="accent" style="padding-left: 8px;">
        <mat-icon>arrow_left</mat-icon>
        Retour
      </button>
      <button mat-raised-button [disabled]="saving || !studentsList.length || !isEditable" color="primary"
        (click)="save()">
        <mat-icon>check</mat-icon>
        Valider
        <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
          class="icon-loader"></mat-progress-spinner>
      </button>
    </mat-card-actions>
  </mat-card>

</div>

<ng-template #loader>
  <app-loader class="margin" message="Chargement..."></app-loader>
</ng-template>
