import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from '@app/components/_public/home/home.component';
import { AboutComponent } from '@app/components/_public/about/about.component';
import { LoginComponent } from '@app/components/_public/login/login.component';
import { LogoutComponent } from '@app/components/_public/login/logout.component';
import { NewsListComponent, HomeNewsComponent } from '@app/components/_public';
import { HomeBoardComponent } from '@app/components/_public/home-board/home-board.component';
import { SharedModule } from './shared.module';
import { FormViewerComponent } from '@app/components/_public/form-viewer/form-viewer.component';
import { UserEditComponent } from '@app/components/_public/user-edit/user-edit.component';
import { BoardComponent } from '@app/components/_user/board/board.component';
import { ChildrenListComponent } from '@app/components/_user/children/list/children-list.component';
import { PreinscriptionListComponent } from '@app/components/_user/preinscription/list/preinscription-list.component';
import { UserInfoComponent } from '@app/components/_user/user-info/user-info.component';
import { CustomLinksComponent } from '@app/components/_common/menu/custom-links/custom-links.component';
import { MenuComponent } from '@app/components/_common/menu/menu.component';
import { StyleCustomizerComponent } from '@app/components/_elements/style-customizer/style-customizer.component';
import { FactureComponent } from '@app/components/_user/facture/facture.component';
import { FactureListComponent } from '@app/components/_user/facture/list/list.component';
import { FactureDetailComponent } from '@app/components/_user/facture/detail/detail.component';
import { ListReglementComponent } from '@app/components/_user/facture/reglement/list-reglement/list-reglement.component';
import { ReservationListComponent } from '@app/components/_user/reservation/list/reservation-list.component';
import { DetailsReservationComponent } from '@app/components/_user/reservation/details-reservation/details-reservation.component';
import { ReservationPlanningComponent } from '@app/components/_user/reservation/planning/planning.component';
import { ForgottenPasswordComponent } from '@app/components/_public/login/forgotten-password/forgotten-password.component';
import { StickyActionBarComponent } from '@app/components/_common/sticky-action-bar/sticky-action-bar.component';
import { ReservationsComponent } from '@app/components/_user/reservation/reservations.component';
import { RegieFacturesComponent } from '@app/components/_user/facture/regie-factures/regie-factures.component';
import { ReservationActivitiesComponent } from '@app/components/_user/reservation/activities/activities.component';
import { ActivityPreviewComponent } from '@app/components/_user/reservation/activities/activity-preview/activity-preview.component';
import { PeriodeDetailsComponent } from '@app/components/_user/reservation/periode-details/periode-details.component';
import { LegendeIconsComponent } from '@app/components/_user/reservation/legende-icons/legende-icons.component';
import { PaymentBackComponent } from '@app/components/_public/payment/back/payment-back.component';
import { ReglementDetailsComponent } from '@app/components/_user/facture/reglement/details/details.component';
import { TopbarActionsComponent } from '@app/components/_common/topbar-actions/topbar-actions.component';
import { FactureLineTableComponent } from '@app/components/_user/facture/line-table/line-table.component';
import { FilterNewsComponent } from '@app/components/_public/news/filter-news/filter-news.component';
import { NewsByCategoryComponent } from '@app/components/_public/news/news-by-category/news-by-category.component';
import { SelectTimeDialogComponent } from '@app/components/_user/assmat-pointage/assmat-planning/select-time-dialog/select-time-dialog.component';
import { AssmatPlanningComponent } from '@app/components/_user/assmat-pointage/assmat-planning/assmat-planning.component';
import { UpdateEmailComponent } from '@app/components/_user/update-email/update-email.component';
import { PaymentRedirectDialogComponent } from '@app/components/_common/payment-redirect-dialog/payment-redirect-dialog.component';
import { EditionListComponent } from '@app/components/_user/edition/edition-list.component';
import { PaymentStatusComponent } from '@app/components/_common/payment-status/payment-status.component';
import { ReservationSummaryComponent } from '@app/components/_user/reservation/summary/summary.component';
import { BannerComponent } from '@app/components/_common/banner/banner.component';
import { BreadcrumbComponent } from '@app/components/_common/breadcrumb/breadcrumb.component';
import { NewPasswordComponent } from '@app/components/_elements/new-password/new-password.component';
import { UpdatePasswordComponent } from '@app/components/_user/update-password/update-password.component';
import { ThemeSwitchComponent } from '@app/components/_common/theme-switch/theme-switch.component';
import { SimpleDateCalendarComponent } from '@app/components/_elements/simple-date-calendar/simple-date-calendar.component';
import { OpenIdFormComponent } from '@app/components/_elements/open-id-connect/open-id-connect-form.component';
import { TotpVerifyComponent } from '@app/components/_elements/totp-verify/totp-verify.component';
import { EnseignantPlanningComponent } from '@app/components/_user/enseignant-pointage/enseignant-planning/enseignant-planning.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    LoginComponent,
    LogoutComponent,
    UserEditComponent,
    NewsListComponent,
    HomeBoardComponent,
    HomeNewsComponent,
    FormViewerComponent,
    FilterNewsComponent,
    NewsByCategoryComponent,
    StyleCustomizerComponent,
    BannerComponent,
    BreadcrumbComponent,
    CustomLinksComponent,
    MenuComponent,
    StickyActionBarComponent,
    ThemeSwitchComponent,
    PaymentRedirectDialogComponent,
    PaymentStatusComponent,
    NewPasswordComponent,
    UpdatePasswordComponent,
    // @TODO: find a solution to move these to UserModule, but UserBoard uses all these in admin for now ..
    BoardComponent,
    UserInfoComponent,
    ChildrenListComponent,
    PreinscriptionListComponent,
    // Facturaction (includes reglements & paiements)
    FactureComponent,
    RegieFacturesComponent,
    FactureListComponent,
    FactureDetailComponent,
    FactureLineTableComponent,
    ListReglementComponent,
    ReglementDetailsComponent,
    PaymentBackComponent,
    // Reservations
    ReservationsComponent,
    ReservationListComponent,
    DetailsReservationComponent,
    PeriodeDetailsComponent,
    LegendeIconsComponent,
    ReservationPlanningComponent,
    ReservationActivitiesComponent,
    ActivityPreviewComponent,
    ReservationSummaryComponent,
    SimpleDateCalendarComponent,
    // Misc
    ForgottenPasswordComponent,
    TopbarActionsComponent,
    UpdateEmailComponent,
    OpenIdFormComponent,
    TotpVerifyComponent,
    // Assmat / pointage
    AssmatPlanningComponent,
    SelectTimeDialogComponent,
    // Edition
    EditionListComponent,
    //Enseignant Pointage
    EnseignantPlanningComponent
  ],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [
    LoginComponent,
    HomeBoardComponent,
    BoardComponent,
    FormViewerComponent,
    CustomLinksComponent,
    MenuComponent,
    StyleCustomizerComponent,
    ReservationPlanningComponent,
    ReservationListComponent,
    ReservationActivitiesComponent,
    PeriodeDetailsComponent,
    LegendeIconsComponent,
    StickyActionBarComponent,
    ActivityPreviewComponent,
    ListReglementComponent,
    TopbarActionsComponent,
    ThemeSwitchComponent,
    FactureDetailComponent,
    DetailsReservationComponent,
    FactureLineTableComponent,
    FilterNewsComponent,
    AssmatPlanningComponent,
    SelectTimeDialogComponent,
    PaymentStatusComponent,
    ReservationSummaryComponent,
    BannerComponent,
    BreadcrumbComponent,
    NewPasswordComponent,
    SimpleDateCalendarComponent,
    EnseignantPlanningComponent,
    UserEditComponent
  ]
})
export class PublicModule { }
