import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnneeScolaire, CogitoInscPreIsnc, ConfigCogito } from '@app/models/cogito';
import { Usager, UsagerChild } from '@app/models/consumer';
import { FamilyService, PermissionService } from '@app/services';
import { CogitoService } from '@app/services/cogito.service';
import { Subject, forkJoin } from 'rxjs/';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface UsagerForFilter {
  id: number,
  nom: string,
  prenom: string
}

@Component({
  selector: 'app-cogito-inscriptions',
  templateUrl: './cogito-inscriptions.component.html',
  styleUrls: ['./cogito-inscriptions.component.scss']
})
export class CogitoInscriptionsComponent implements OnInit {

  inscriptions: CogitoInscPreIsnc[];
  inscriptionsFiltered: CogitoInscPreIsnc[];
  permToCreate: boolean;
  permToEdit: boolean;
  inscriptionsActive: boolean;
  anneesScolaires: AnneeScolaire[];
  isLoading: boolean;
  config: ConfigCogito;
  drawerOpened = false;
  filter: { children: UsagerChild[] } = { children: [] };
  childList: UsagerChild[];
  listUsagers: UsagerForFilter[];
  filterUsagers: UsagerForFilter[] = [];

  onDestroy$ = new Subject();

  constructor(
    private permService: PermissionService,
    private familyService: FamilyService,
    private cogitoService: CogitoService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.permToEdit = this.permService.hasPermission('cogito_inscriptions_edit') && this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('cogito_inscriptions_create') && this.familyService.currentFamily.active;

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.inscriptions = null; }),
      switchMap(family => this.familyService.getFamilyInscriptions(family, 'Cogito'))
    ).subscribe((data: any) => {
      this.config = data.config;
      this.inscriptions = data.inscriptionsTab.reverse();
      this.inscriptions.forEach((insc: CogitoInscPreIsnc) => this.setEditableByAnneeProperty(insc));
      this.inscriptionsActive = this.cogitoService.checkPermInscription(this.config);
      this.listUsagers = this.setListUsager(this.inscriptions);
      this.inscriptionsFiltered = [...this.inscriptions];
      this.isLoading = true;
    })
  }

  setListUsager(inscriptions: CogitoInscPreIsnc[]): UsagerForFilter[] {
    let listUsagersInscriptions = inscriptions.map(insc => {
      return { id: insc.idEnfant, nom: insc.nom, prenom: insc.prenom }
    })

    return listUsagersInscriptions.filter((value, index, self) =>
      index === self.findIndex((child) => (
        child.id === value.id
      ))
    )

  }

  onClickEdit(item: CogitoInscPreIsnc) {
    this.router.navigate([`account/cogito-inscriptions/${item.idInscriptionCogito}`]);
  }

  setEditableByAnneeProperty(inscription: CogitoInscPreIsnc) {
    let tabIdAnneeScolaireNonEditable = []

    if (this.config?.listAnneesScolairesNonEditable.length) {
      tabIdAnneeScolaireNonEditable = this.config.listAnneesScolairesNonEditable.map(anneeSco => anneeSco.idAnneeScolaire);
    }

    if (tabIdAnneeScolaireNonEditable.includes(inscription.anneeScolaire.idAnneeScolaire)) {
      inscription.editableByAnnee = false;
    } else {
      inscription.editableByAnnee = true;
    }
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  changeSelectChildren($event) {
    this.applyFilter();
  }

  applyFilter() {
    if (this.filterUsagers.length) {
      this.inscriptionsFiltered = this.inscriptions.filter((insc: CogitoInscPreIsnc) => {
        return this.filterUsagers.some((usager: UsagerForFilter) => {
          return usager.id === insc.idEnfant;
        })
      })
    } else {
      this.inscriptionsFiltered = this.inscriptions;
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
