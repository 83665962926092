<div *ngIf="appUpdate" class="new-version-available mat-elevation-z16">
  <span class="title">
    <mat-icon>update</mat-icon>
    Nouvelle version disponible
  </span>

  <button mat-raised-button (click)="reloadApp()">
    <mat-icon>refresh</mat-icon>
    Recharger
  </button>
  <div class="background"></div>
</div>
