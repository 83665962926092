import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformService, FamilyService, AuthenticationService, UserService } from '@app/services';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BoardCard } from '@app/models/board-card';
import { forkJoin, of, Subject } from 'rxjs';
import { AssmatService } from '@app/services/assmat.service';
import { AccountType, User } from '@app/models/user';
import { BaseConfigService } from '@app/services/config/base.service';
import { Router } from '@angular/router';

import { Link } from '@app/models/custom-link';





@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  typeAccount: AccountType;
  boardCards: BoardCard[];
  accountVars: any;
  loading = false;
  destroy$ = new Subject();
  userValue: User;
  derniereModifConfAssmat: any;
  derniereModifConfFamily: any;
  localBoardHomeConfAssmat: any;
  localBoardHomeConfFamily: any;
  boardLink: Link[];

  constructor(
    private familyService: FamilyService,
    private accountService: AuthenticationService,
    private adulteService: UserService,
    public platformService: PlatformService,
    private assmatService: AssmatService,
    private baseConfigService: BaseConfigService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.accountService.accountTypeChange$
      .pipe(
        tap(_ => this.loading = true),
        takeUntil(this.destroy$))
      .subscribe(typeAccount => {

        this.typeAccount = typeAccount;

        switch (this.typeAccount) {
          default: // or = 'noAccount'
            this.loading = false;
            break;

          case 'assmat':
            let confAssmat = this.baseConfigService.getFirstConf("board-assmat").content;
            setTimeout(() => {
              if (this.accountService.currentUserValue) {
                forkJoin([
                  this.assmatService.getTypeEtablissementsEnCoursByIdAssmat(this.accountService.currentUserValue?.idAssmat),
                  this.assmatService.getAssmatVariables(this.accountService.currentUserValue?.idAssmat)
                ]).subscribe(([typeEtabs, accountVars]) => {
                  const boardCards = confAssmat.cards;
                  this.accountVars = accountVars;
                  this.filterBoardCardsByTypeEtabsEnCoursAssmat(typeEtabs, boardCards);
                  this.loading = false;
                })
              }
            });
            break;
          case 'enseignant':
            let confEnseignant = this.baseConfigService.getFirstConf("board-enseignant").content;
            this.boardCards = confEnseignant.cards.filter(card => card.enabled);
            this.loading = false;
            break;
          case 'family':
            let confFamily = this.baseConfigService.getFirstConf("board-user").content;
            confFamily.cards?.forEach(card => {
              if (card.component === 'reservations') {
                if (!this.accountService.currentUserValue || this.accountService.currentUserValue.idAdulte < 1) {
                  card.disabledCause = 'reservation.info.invalide_account_message';
                } else if (!!this.adulteService.currentAdulte && !this.adulteService.currentAdulte.emailConfirm) {
                  card.disabledCause = 'Votre email doit être confirmé';
                }
              }
            });
            this.boardCards = confFamily.cards.filter(card => card.enabled);
            this.loading = false;
            break;

          case 'admin':
            this.router.navigate(['admin']);

        }

      });

    // Could add these info to basic familyData (that we get right after login), but conflicts with local storage ...
    this.familyService.currentFamilyChange$.pipe(
      takeUntil(this.destroy$),
      switchMap(fam => fam ? this.familyService.getFamilyVariables(fam.id) : of({}))
    ).subscribe((vars: any) => {
      this.accountVars = vars;
      this.filterBoardCardsByEtablissementsForFamilly();
      // console.log("boardCards", this.boardCards)
      this.familyService.reservationsToPay$.next(vars.reservationsEnAttentePaiement);
    });
  }

  filterBoardCardsByTypeEtabsEnCoursAssmat(typeEtab, boardCards) {
    if (!typeEtab?.mentalo && typeEtab?.mikado) {
      this.boardCards = boardCards.filter(card => card.component !== "contrat-accueil-mentalo" &&
        card.component !== "animations" &&
        card.component !== "reunions" &&
        card.enabled);
    }
    if (!typeEtab?.mikado && typeEtab?.mentalo) {
      this.boardCards = boardCards.filter(card => card.component !== "assmat-pointage" && card.enabled);

    }
    if (typeEtab?.mentalo && typeEtab?.mikado) {
      this.boardCards = boardCards.filter(card => card.enabled);
    }

    if ((!typeEtab?.mentalo && !typeEtab?.mikado) || !typeEtab) {
      this.boardCards = boardCards.filter(card => (
        card.component !== "assmat-pointage" &&
        card.component !== "contrat-accueil-mentalo" &&
        card.component !== "reunions" &&
        card.component !== "animations" &&
        card.enabled))
    }
  }

  //filter to select the cards by etablissement
  filterBoardCardsByEtablissementsForFamilly() {
    return this.boardCards?.filter(card =>
      !card.isNotPublic || card.accueilsIds?.some(id =>
        this.accountVars.etablissementAccueil?.some((accEtb) => accEtb.accueils.includes(id)))
    )

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
