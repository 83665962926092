<mat-card class="conteneur">
  <mat-card-header>
    <mat-card-title class="fx-direction-row fx-start-center fx-gap-10">
      <mat-icon class="icon">vpn_key</mat-icon>
      <span>Réinitialisation de votre mot de passe</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <form [formGroup]="forgottenPassForm" (ngSubmit)="onSubmit()">

      <div class="content" *ngIf="response==''">

        <div class="mat-h3" style="margin-bottom: 10px;">
          Veuillez saisir votre adresse e-mail
        </div>

        <mat-form-field style="width: 100%;">
          <mat-label>Adresse email</mat-label>
          <input type="text" matInput formControlName="email" required name="email">
          <mat-error>
            Email incorrect
          </mat-error>
        </mat-form-field>

      </div>

      <div class="content" *ngIf="response!=''" [innerHTML]="response | safe:'html'" style="margin-bottom: 20px;"></div>


      <mat-card-actions class="fx-direction-col fx-between-center fx-gap-10">

        <button mat-raised-button type="submit" class="submit-bt" (click)="onSubmit()" color="primary"
          *ngIf="response==''" [disabled]="isLoading">
          <span>Valider</span>
          <app-loader size="1" class="" *ngIf="isLoading"></app-loader>
        </button>

        <a href="#" routerLink="/login" *ngIf="response==''">Retour </a>
        <button type="button" routerLink="/login" *ngIf="response!=''" mat-raised-button color="primary"
          (click)="$event.preventDefault();">Retour </button>

      </mat-card-actions>
    </form>
  </mat-card-content>

</mat-card>
