import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PointageRubrique } from '@app/models/pointage';
import { JourCours, PointageEnseignantConfig, PresenceAbsence, Student } from '@app/models/pointage-enseignant';
import { BaseConfigService, PlatformService, SnackbarService, UserService } from '@app/services';
import { EnseignantService } from '@app/services/enseignant.service';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-students-pointage',
  templateUrl: './students-pointage.component.html',
  styleUrls: ['./students-pointage.component.scss']
})
export class StudentsPointageComponent implements OnInit {

  studentsList: Student[];
  jourCours: JourCours;
  presencesAbsences: PresenceAbsence[];
  rubriques: PointageRubrique[];
  loading: boolean = true;
  selectedRubriques: { [idInscription: number]: number } = {};
  saving: boolean;
  idEnseignant: number;
  idJourCours: number;
  isEditable: boolean;

  constructor(
    private enseignantService: EnseignantService,
    private userService: UserService,
    private configService: BaseConfigService,
    private snackbar: SnackbarService,
    private location: Location,
    public route: ActivatedRoute,
    public platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.idEnseignant = this.userService.currentUser.idEnseignant;
    this.idJourCours = +this.route.snapshot.paramMap.get('idJourCours');
    this.loadData();
  }

  loadData() {
    this.enseignantService.getJourCours(this.idJourCours, this.idEnseignant).pipe(
      switchMap((jourCours: JourCours) => {
        return forkJoin([
          of(jourCours),
          this.enseignantService.getListStudents(jourCours.idCours, jourCours.idJourCours, this.idEnseignant),
          this.enseignantService.getPresence(jourCours.idCours, jourCours.date, jourCours.idJourCours, this.idEnseignant),
          this.configService.get<PointageEnseignantConfig>('enseignant-pointage')
        ])
      })
    ).subscribe(([jourCours, students, presencesAbsences, config]: [JourCours, Student[], any[], PointageEnseignantConfig]) => {
      this.jourCours = jourCours;
      this.rubriques = config.rubriques;
      this.presencesAbsences = presencesAbsences;

      this.studentsList = this.linkStudentsWithPresences(students, presencesAbsences);
      this.studentsList.sort((a, b) => a.nomReferent.toLowerCase().localeCompare(b.nomReferent.toLowerCase()));

      const dateStr = jourCours.date;
      this.isEditable = this.enseignantService.isEditableDate(dateStr, config)

      this.loading = false;
      this.saving = false;
    })
  }

  // Retourne la couleur associée à la rubrique sélectionnée
  getRubriqueColor(idInscription: number): string {
    const rubriqueId = this.selectedRubriques[idInscription];
    let color = this.rubriques.find(rub => rub.id === rubriqueId)?.couleurPortail || this.rubriques.find(rub => rub.id === rubriqueId)?.couleur;
    return color || '';
  }

  linkStudentsWithPresences(students: Student[], presencesAbsences: PresenceAbsence[]): Student[] {
    return students.map((student) => {
      const matchingPresences = presencesAbsences.filter(
        (presence) => presence.idInscription === student.idInscription
      );

      if (matchingPresences.length > 0) {

        let [reellePresence, previsionnelPresence]: [PresenceAbsence, PresenceAbsence] = matchingPresences.reduce(([nonPrev, prev], presence) => {
          if (presence.previsionnel) return [nonPrev, presence];
          return [presence, prev];
        }, [null, null]);


        const selectedPresence = reellePresence || previsionnelPresence;

        // Met à jour `selectedRubriques` avec uniquement l'idRubriquePlanning
        this.selectedRubriques[student.idInscription] = selectedPresence.idRubriquePlanning;

        if (previsionnelPresence) {
          previsionnelPresence.rubriqueName = this.rubriques.find(rub => rub.id === previsionnelPresence.idRubriquePlanning)?.name;
          previsionnelPresence.rubriqueColor = this.rubriques.find(rub => rub.id === previsionnelPresence.idRubriquePlanning)?.couleurPortail || this.rubriques.find(rub => rub.id === previsionnelPresence.idRubriquePlanning)?.couleur;
        }

        // Met à jour l'objet `Student` avec l'objet unique `presenceAbsence`
        return {
          ...student,
          presenceAbsenceReel: reellePresence,
          presenceAbsencePrev: previsionnelPresence,
          presenceAbsence: selectedPresence
        };
      }

      // Si aucune présence n'existe
      return {
        ...student
      };
    });
  };

  buildPresenceAbsence(data: { [key: number]: number }): PresenceAbsence[] {
    return Object.keys(data)
      .map((idInscriptionStr) => {
        const idInscription = Number(idInscriptionStr);
        const idRubriquePlanning = data[idInscription];
        const rubrique = this.rubriques.find(rub => rub.id === idRubriquePlanning);
        const type = rubrique ? rubrique.presenceAbsence : '';
        const student = this.studentsList.find(student => student.idInscription === idInscription);
        const currentPresenceAbsence = student.presenceAbsence;

        // Vérifie si une présence prévisionnelle existe
        if (currentPresenceAbsence && currentPresenceAbsence.previsionnel) {
          // Crée une nouvelle présence réelle à partir de la prévisionnelle
          const presence = {
            ...currentPresenceAbsence,
            idPresenceAbsence: null,
            idRubriquePlanning: idRubriquePlanning,
            previsionnel: false,  // Marque comme réelle
            type: type
          }

          delete presence.idPresenceAbsence;

          return presence;
        }

        // Vérifie si la présence réelle a changé
        if (currentPresenceAbsence && currentPresenceAbsence.idRubriquePlanning !== idRubriquePlanning) {
          return {
            ...currentPresenceAbsence,
            idRubriquePlanning: idRubriquePlanning,
            type: type
          };
        }

        // Si aucune présence n'existe, crée une nouvelle entrée
        if (!currentPresenceAbsence) {
          return {
            idInscription: idInscription,
            idRubriquePlanning: idRubriquePlanning,
            idCours: this.jourCours.idCours,
            date: this.jourCours.date,
            plage1Deb: this.jourCours.heureDebut,
            plage1Fin: this.jourCours.heureFin,
            previsionnel: false,  // Nouvelle présence réelle
            type: type
          };
        }

        return null; // Pas de changement
      })
      .filter(presence => presence !== null); // Ne garde que les entrées modifiées ou nouvelles
  }

  getMessageErrRapport(response: any[]) {
    let details: string = '';

    response.forEach(resp => {
      if (!resp.result) {
        details += `<br> - ${resp.msg}`;
      }
    })
    return details;
  }

  save() {
    const presencesAbsencesEdited = this.buildPresenceAbsence(this.selectedRubriques);

    if (!presencesAbsencesEdited.length) {
      this.snackbar.info('Aucune modification à enregistrer !');
    } else {
      this.saving = true;
      this.enseignantService.savePresencesAbsences(presencesAbsencesEdited, this.idEnseignant).subscribe((response: any) => {
        if (response) {
          const groupedPresenceAbsences = response.map(res => res.presenceAbsence);
          const msgRapport = this.getMessageErrRapport(response);

          if (msgRapport) {
            this.snackbar.open({ message: `Enregistrement réussi !<br>Exceptions : ${msgRapport}`, type: 'info', textButton: 'Ok' });
          }

          this.studentsList.forEach(student => {
            const matchingItem = groupedPresenceAbsences.find(res => res.idInscription === student.idInscription);
            if (matchingItem) {
              student.presenceAbsence = { ...matchingItem };
              student.presenceAbsenceReel = { ...matchingItem };
            }
          });

          this.snackbar.info('Enregistrement réussi !');
          this.saving = false;
        }
      },
        err => {
          if (err) {
            this.snackbar.error('Une erreur est survenue lors de l\'enregistrement');
          }
          this.saving = false;
        })
    }
  }

  onClickBack() {
    this.location.back();
  }
}
