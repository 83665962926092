<div *ngIf="!loading; else loader" #calendarWrapper class="calendar-view-wrapper"
  [ngClass]="{'small-screen': isSmallScreen, 'very-small-screen': isVerySmallScreen , 'large-screen': isLargeScreen}">

  <mat-form-field style="display: none;">
    <input matInput [matDatepicker]="picker" (dateChange)="onDateSelected($event.value)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker touchUi #picker startView="year"></mat-datepicker>
  </mat-form-field>

  <full-calendar #calendar [options]="calendarOptions"></full-calendar>

</div>

<ng-template #loader>
  <app-loader class="margin">{{ 'reservation.loader.planning'|trans }}</app-loader>
</ng-template>
