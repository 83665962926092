<ng-template appTopbarActions>
  <button mat-mini-fab color="accent" (click)="toggleContratsOptions()" matTooltip="Options d'affichage">
    <mat-icon>settings</mat-icon>
  </button>
</ng-template>

<mat-drawer-container>

  <mat-drawer-content>

    <div id="contrat-conteneur" class="thin-scrollbar" *ngIf="contrats; else loader">
      <div class="fx-direction-col">

        <mat-card *ngIf="permToCreate" class="card card-add">
          <mat-card-header class="card-header fx-direction-row fx-center-center">
            <mat-card-title class="fx-direction-row fx-center-center">
              Nouveau contrat d'accueil
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="fx-direction-row fx-center-center">
            <button mat-fab color="primary" matTooltip="Nouveau contrat d'accueil"
              routerLink="/account/contrat-accueil-mentalo/add">
              <mat-icon>add</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>

        <mat-card class="card" *ngFor="let contrat of contratsFiltered"
          [ngStyle]="{'opacity': !contrat.currentContrat ? 0.7 : 1}">
          <mat-card-header class="card-header fx-direction-row fx-between-center">
            <mat-card-title class="fx-direction-row fx-start-center"
              [ngStyle]="{'font-size': platformService.isMobile ? '16px' : '20px'}">
              <mat-icon [color]="contrat.currentContrat ? 'primary':'warn'"
                [matTooltip]="contrat.currentContrat ? 'Actif':'Inactif'" style="margin-right: 10px;">folder</mat-icon>
              <!-- <app-icon icon="folder" [color]="contrat.currentContrat ? '#28A745':'#F33A50'" type="fas" size="24"
                style="margin-right: 10px;" [matTooltip]="contrat.currentContrat ? 'Actif':'Inactif'"></app-icon> -->
              <span>{{contrat.dateDebut ? "Du " : "Du ../../.... "}}{{contrat.dateDebut | date:"shortDate"}}</span>
              <span
                style="margin-left: 3px;">{{contrat.dateFin ? " au " : " au ../../.... "}}{{contrat.dateFin | date:"shortDate"}}</span>
            </mat-card-title>
            <button *ngIf="contrat.currentContrat" style="margin-bottom: 20px;" mat-mini-fab color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-card-header>

          <mat-menu class="mat-menu-child" #menu="matMenu" xPosition="before">
            <button *ngFor="let formStep of formStepContratAccueil" mat-menu-item
              (click)="openForm(contrat.idInscriptionMentalo, formStep.stepName)">
              <mat-icon color="primary">{{ readOnly ? 'visibility' : 'edit' }}</mat-icon>
              {{ formStep.label }}
            </button>
          </mat-menu>

          <mat-card-content style="margin-left: 20px;">

            <p><b>Enfant : </b> {{contrat.nom}} {{contrat.prenom}}</p>
            <p *ngIf="contrat.etablissement"><b>Etablissement : </b>{{contrat.etablissement}}</p>
            <p *ngIf="contrat.accueil"><b>Accueil : </b>{{contrat.accueil}}</p>
            <p *ngIf="contrat.agrementSecondaire"><b>Agrément secondaire : </b> {{contrat.agrementSecondaire}}</p>

            <p class="fx-direction-row fx-wrap fx-start-center fx-gap-2">
              <b>Statut : </b>
              <app-icon *ngIf="contrat.decision ==='Refusée'" type="fa" icon="times-circle" class="icon-decision warn">
              </app-icon>
              <app-icon *ngIf="contrat.decision ==='Acceptée'" type="fa" icon="check-circle"
                class="icon-decision primary">
              </app-icon>
              <app-icon *ngIf="contrat.decision ==='Abandon'" type="fa" icon="trash" class="icon-decision"></app-icon>
              <app-icon *ngIf="contrat.decision ==='En attente'" type="fa" icon="question-circle"
                class="icon-decision accent"></app-icon>
              <app-icon *ngIf="contrat.decision ===''" type="fa" icon="question-circle" class="icon-decision accent">
              </app-icon>
              <span>{{ contrat.decision || 'En attente'}}</span>
            </p>

          </mat-card-content>
        </mat-card>
      </div>

      <div *ngIf="!contrats.length && readOnly">
        <h2 style="text-align: center;">Vous n'avez pas de contrat(s) d'accueil.</h2>
      </div>
    </div>
  </mat-drawer-content>

  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar class="preview-header fx-direction-row fx-between-center" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div class="fx-direction-col" style="margin: 5px; padding: 5px;">
      <mat-slide-toggle style="padding-left: 5px;" [(ngModel)]="showCurrentContrats"
        (ngModelChange)="toggleShowCurrentsContrats($event)" class="slideToggle">
        <span>Afficher uniquement les contrats d'accueil actif.</span>
      </mat-slide-toggle>

      <!-- <mat-form-field style="margin-top: 5px;">
        <mat-label>Trier par</mat-label>
        <mat-select [(ngModel)]="typeSortContrats" (ngModelChange)="sortContrats($event)" name="sort">
          <mat-option value="">--Aucun--</mat-option>
          <mat-option value="byStartDate">Date de Début</mat-option>
          <mat-option value="byEndDate">Date de Fin</mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
