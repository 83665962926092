import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Cours } from '@app/models/lasido';
import { PointageEnseignantConfig } from '@app/models/pointage-enseignant';
import { BaseConfigService, UserService } from '@app/services';
import { EnseignantService } from '@app/services/enseignant.service';
import { StorageService } from '@app/services/storage.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-enseignant-pointage',
  templateUrl: './enseignant-pointage.component.html',
  styleUrls: ['./enseignant-pointage.component.scss']
})
export class EnseignantPointageComponent implements OnInit {

  loading = true;
  idEnseignant: number;
  config: PointageEnseignantConfig;
  coursList: Cours[];
  isListView: boolean;

  constructor(
    private userService: UserService,
    private configService: BaseConfigService,
    private enseignantService: EnseignantService,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {

    this.isListView = this.storageService.getItem('isListView') || false;
    this.idEnseignant = this.userService.currentUser.idEnseignant;

    forkJoin([
      this.configService.get<PointageEnseignantConfig>('enseignant-pointage'),
      this.enseignantService.getCoursList(this.idEnseignant)
    ]).subscribe(([config, coursList]) => {

      coursList = coursList.map(c => ({
        ...c,
        couleur: this.genererCouleurAleatoire()
      }));
      this.config = config;
      this.coursList = coursList;

      this.loading = false;
    })
  }

  genererCouleurAleatoire() {
    return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
  }

  switchView(event: MatSlideToggleChange): void {
    this.isListView = event.checked;
    this.storageService.setItem('isListView', event.checked);
  }

}
