<mat-card style="max-width: 500px; margin-inline: auto; margin-top: 20px;">
  <div class="card-content" *ngIf="listUsagers;else loader">
    <mat-form-field *ngIf="listUsagers">
      <mat-label *ngIf="step.choiceTypeSelection === 'usager'">{{ 'entity.usager'|trans }}</mat-label>
      <mat-label *ngIf="step.choiceTypeSelection === 'enfant'">Enfant</mat-label>
      <mat-label *ngIf="step.choiceTypeSelection === 'adulte'">Adulte</mat-label>
      <mat-select required panelClass="usager-select" [(ngModel)]="selectedUsager">
        <mat-optgroup *ngIf="step.choiceTypeSelection === 'usager' || step.choiceTypeSelection === 'adulte'">
          {{ 'entity.adults'|trans }}
          <ng-container *ngFor="let usager of listUsagers">
            <mat-option *ngIf="usager.type === 'adulte'" [value]="usager">
              {{ usager.prenom }} {{ usager.nom }}
            </mat-option>
          </ng-container>
        </mat-optgroup>

        <mat-optgroup *ngIf="step.choiceTypeSelection === 'usager' || step.choiceTypeSelection === 'enfant'">
          {{ 'entity.children'|trans }}
          <ng-container *ngFor="let usager of listUsagers">
            <mat-option *ngIf="usager.type === 'enfant'" [value]="usager">
              {{ usager.prenom }} {{ usager.nom }}
              <span *ngIf="usager.age">({{ 'enfant.age'|trans:{age: usager.age.years} }})</span>
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
    <button *ngIf="permToAddChild && (step.choiceTypeSelection === 'enfant' || step.choiceTypeSelection === 'usager')"
      mat-stroked-button color="primary" (click)="openForm('children')" class="btn-add">
      <mat-icon style="margin-right: 5px;">add_box</mat-icon><span>Ajouter un Enfant</span>
    </button>
    <button mat-stroked-button color="primary"
      *ngIf="(step.choiceTypeSelection === 'usager' || step.choiceTypeSelection === 'adulte') && !conjointExist && permToEditFoyer"
      (click)="openForm('conjoint')" class="btn-add">
      <mat-icon style="margin-right: 5px;">add_box</mat-icon><span>Ajouter un Conjoint</span>
    </button>

    <mat-card-actions align="center">

      <button mat-raised-button (click)="onValider()" color="primary" [disabled]="!selectedUsager">Valider</button>
    </mat-card-actions>
  </div>
</mat-card>



<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
