import { Injectable } from '@angular/core';
import { User, Role } from '@app/models/user';
import { HttpClient } from '@angular/common/http';
import { ApiCrudService } from './api-crud.service';
import { AuthenticationService } from './authentication.service';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { tap, distinctUntilChanged } from 'rxjs/operators';
import { Adulte } from '@app/models/adulte';
import { FamilyService } from './family.service';
import { PermissionService } from './permission.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfirmEmailComponent } from '@app/components/_elements/confirm-email/confirm-email.component';

// Difference between User and Adult is here a bit confusing ...
// @TODO: rename to "AdulteService"
@Injectable({ providedIn: 'root' })
export class UserService extends ApiCrudService<User> {

  url = 'users';
  formUrl = 'users/#idUser#/form/#formPart#';

  userCreated: boolean = false;

  private currentAdulteSubject = new BehaviorSubject<Adulte>(null);
  currentAdulte$: Observable<Adulte> = this.currentAdulteSubject.asObservable();
  currentAdulteChange$: Observable<Adulte> = this.currentAdulte$.pipe(distinctUntilChanged((x, y) => x?.id === y?.id)); // A "real" user change is when the id changes;

  constructor(
    protected http: HttpClient,
    private authService: AuthenticationService,
    private familyService: FamilyService,
    private permService: PermissionService,
    private bottomSheet: MatBottomSheet
  ) {
    super();
  }

  // shortcut to "AuthService", confusion caused by this service name ..
  get currentUser() {
    return this.authService.currentUserValue;
  }

  get currentAdulte() {
    return this.currentAdulteSubject.value;
  }

  init() {
    // Watch for User logs in/out and update linked data
    // => Loads User data from API when app starts
    this.authService.currentUserChange$.subscribe((user: User) => {
      if (user) {
        // If User is linked to an Adulte, load it (better always be the case, for now)
        if (user.role === Role.User && user.idAdulte) {
          // Reload the Adulte linked to current User, only when needed ...
          this.getCurrentUserData().subscribe(adulte => {

            if (adulte.assistantMaternel) {
              this.authService.storeIdAssmat(adulte.assistantMaternel.idAssistantMaternel);
            }

            if (adulte.enseignant) {
              this.authService.storeIdEnseignant(adulte.enseignant.idEnseignant);
            }

            this.permService.permission$.subscribe(perm => {
              if (!perm.enabled_assmat_account && !perm.enabled_enseignant_account) {
                this.authService.setAccountType('family');
              } else {
                this.authService.setAccountType(
                  user.accountType ||
                  (this.familyService.currentFamily ? 'family' :
                    (adulte.assistantMaternel ? 'assmat' :
                      adulte.enseignant ? 'enseignant' : 'noAccount'))
                );
              }
            });
            if (!adulte.emailConfirm && !this.userCreated) {
              this.bottomSheet.open(ConfirmEmailComponent);
            }
          });
        } else if (user.role === Role.Admin || user.role === Role.Manager) {
          this.authService.setAccountType('admin');
        }

      } else {
        // Logout => clear everything
        this.setCurrentAdulte(null);
        this.familyService.setCurrentFamily(null);
      }
    })
  }

  setCurrentAdulte(adulte: Adulte) {
    this.currentAdulteSubject.next(adulte);
  }

  create(data: User | any) {
    this.userCreated = true;
    return this.http.post(this.url, data, this.httpOptions);
  }

  update(data: User | any, id?: number, part?: string) {
    part = part ? part : '';
    return this.http.put(`${this.url}/${id}/${part}`, data, this.httpOptions);
  }

  getCurrentUserData(): Observable<Adulte> {
    const userDataObservable = (this.currentUser && this.currentUser.role === Role.User) ?
      this.http.get<Adulte>(this.url + '/' + this.currentUser.id) : of(null)

    return userDataObservable.pipe(
      tap((adulte: Adulte) => {
        this.setCurrentAdulte(adulte)
        if (adulte?.families) {
          this.familyService.defineCurrentFamily(adulte.families);
        }
      })
    );
  }

  getFormData(id, part?) {

    part = part ? part : '';
    const url = this.formUrl.replace('#idUser#', id).replace('#formPart#', part);
    return this.http.get(url, this.httpOptions);
  }

  updatePassword(data: object) {
    return this.http.post(`${this.url}/update-password`, data);
  }

  checkLoginAvailability(login: string) {
    return this.http.post(`${this.url}/login-availability`, { login });
  }

  getTokenType(token: string) {
    return this.http.get<{ type: string, user: User, errorMessage?: string }>(`${this.url}/token-info/${token}`);
  }

  confirmEmail(token: string) {
    return this.http.post<any>(`${this.url}/confirm-email`, { token });
  }

  getCurrentFamilyAdults() {
    return this.http.get<Adulte[]>(this.familyService.getCurrentFamilyDependantUrl('adultes'), this.httpOptions);
  }

  sendConfirmEmail(user: User) {
    return this.http.post(`${this.url}/envoi-confirm-email`, user, this.httpOptions);
  }
}
