<app-dialog-header title="Modifier le document '{{title}}'"></app-dialog-header>
<div mat-dialog-content>
  <div class="fx-direction-row fx-start-center" style="margin-left: 15px;">
    <mat-icon color="primary">arrow_right</mat-icon>
    <h4>Renommer en :</h4>
  </div>
  <mat-form-field style="width: 80%; margin: auto; display: block;">
    <input matInput [(ngModel)]="file.name">
  </mat-form-field>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="file" cdkFocusInitial color="primary">Valider</button>
</div>
