import { Component, OnInit, ViewChild, TemplateRef, ElementRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { StepType } from '@app/forms/config/form-model';
import { UserService, PermissionService, AuthenticationService, FamilyService, FormConfigService, FormHelperService, SnackbarService } from '@app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { FormViewerComponent, SubmitFormEvent } from '../form-viewer/form-viewer.component';
import { StepperOrientation } from '@angular/material/stepper';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime, first, tap } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdresseUpdateService } from '@app/services/adresse-update.service';
import { addressToUpdate } from '@app/models/address';
import { OpenIdConfig } from '@app/models/authentification-config';
import { FormType } from '@app/models/global-config';
import { User, ROLES_HIERARCHY, AccountType } from '@app/models/user';
import { DemarcheService } from '@app/services/demarche.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {

  externalAuth: OpenIdConfig;

  id: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  typeForm: FormType = 'form-user';
  data: any;
  userCreated: boolean;
  emailField;
  isLoading = true;
  isSaving = false;
  loadingMessage = 'Chargement';
  formTitle: string;
  updateFamilyChecked: string;
  updateConjointChecked: string;

  dataUpdateAddress: addressToUpdate;
  manyAddressesDisplay: boolean;
  conjointData: any;
  foyerData: any;

  loginCheckState = '';
  loginCheckLoaderIsMoved = false;
  loginCheckSearch$ = new Subject<string>();
  loginCheckRequest$ = new Subject<boolean>();
  destroy$ = new Subject();
  searchSubscription: Subscription;

  actualStep: any = null;

  containerWidth: number;
  stepperOrientation: StepperOrientation;

  @Input() stepForm: string;
  @Input() fromDemarche: boolean;
  @Input() demarcheTitle: string;
  @Output() onValidate = new EventEmitter<any>();

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;
  @ViewChild('loginAlreadyExistQuestion', { static: true }) loginAlreadyExistQuestionTemplate: TemplateRef<any>;
  @ViewChild('loginCheckindicator', { static: true }) loginCheckindicator: ElementRef;
  @ViewChild('updateAddressBottomSheet', { static: true }) updateAddressBottomSheet: TemplateRef<any>;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private userService: UserService,
    private authService: AuthenticationService,
    private permService: PermissionService,
    private familyService: FamilyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    public bottomSheet: MatBottomSheet,
    private addressUpdateService: AdresseUpdateService,
    private snackBar: SnackbarService,
    private demarcheService: DemarcheService
  ) { }

  ngOnInit() {
    this.authService.getExternalAuthConf().pipe(takeUntil(this.destroy$))
      .subscribe((conf: OpenIdConfig) => this.externalAuth = conf);

    this.step = this.stepForm ? this.stepForm : this.route.snapshot.paramMap.get('step');

    this.id = (this.userService.currentUser ? this.userService.currentUser.id : null) || null;
    this.readOnly = (this.id && !this.permService.hasPermission('account_edit')) || (this.familyService.currentFamily ? !this.familyService.currentFamily.active : false);

    this.userService.getFormData(this.id, this.step)
      .subscribe((result: any) => {

        const userData = this.userService.currentAdulte

        if (this.fromDemarche) {
          this.formTitle = this.demarcheTitle;
        } else {
          this.formTitle = this.userService.currentUser ? `${userData.prenom} ${userData.nom}` : null;
        }

        if (result.config) {
          this.form = this.formConfigService.getFormView(result.config).filter(f => f.enabled);
        }

        this.data = !result.data ? { modeCreation: true } : result.data;

        this.emailField = this.formConfigService.findFieldByName(this.form, 'email');

        setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
          this.formViewer.handleReturnProgramOnDisplay(result)
        })

        if (!this.id) {

          this.checkOidcValuesForCreatingUser()

          if (this.authService.creatingUserFromOIDC !== null) {

            this.setOidcValuesForCreatingUser()

            // On désactive la saisie sur le champ Nom / Prénom / Date de naissance  (Exigence FranceConnect... mais je pense que ce sera utile pour tous les OIDC)
            this.disableOidcIdentityFields()

          } else {
            // Mode création 'normal' : brancher un validateur async pour vérifier l'existance du login
            this.setLoginAsyncValidator()
          }

        } else {
          // Si on est connecté via OIDC : On désactive la saisie sur le champ Nom / Prénom / Date de naissance  (Exigence FranceConnect... mais je pense que ce sera utile pour tous les OIDC)
          if (this.authService.currentUserValue.fromOidc) {
            this.disableOidcIdentityFields()
          }

        }
        this.onStepChange(0)
        this.isLoading = false;
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkOidcValuesForCreatingUser() {
    //  Check if we come from OIDC Callback
    if (this.authService.creatingUserFromOIDC === null) {
      const creatingUserJSON = sessionStorage.getItem('creatingUser')
      this.authService.creatingUserFromOIDC = creatingUserJSON !== null ? JSON.parse(creatingUserJSON) : null;
    }
  }

  setOidcValuesForCreatingUser() {
    this.data = Object.assign(this.data, this.authService.creatingUserFromOIDC.adulte)

    // S'il y a un email , on retire l'étape 
    if (this.authService.creatingUserFromOIDC.email && this.authService.creatingUserFromOIDC.email !== '') {
      this.form = this.form.filter(step => step.stepName !== 'connexion')
      setTimeout(() => {
        this.formViewer.stepper.next();
      });

    } else { // sinon garde l'étape, mais on retire la saisie du mot de passe
      // let passwordField = this.formConfigService.findFieldByName(this.form, 'passwordGroup');
      let connexionStep = this.form.find(step => step.stepName == 'connexion')
      connexionStep.fields = connexionStep.fields.filter(field => field.key !== 'passwordGroup')

    }
  }

  disableOidcIdentityFields() {
    let fieldNom = this.formConfigService.findFieldByName(this.form, 'nom');
    let fieldPrenom = this.formConfigService.findFieldByName(this.form, 'prenom');
    let fieldDateNaissance = this.formConfigService.findFieldByName(this.form, 'dateNaissance');
    let fieldLieuNaissance = this.formConfigService.findFieldByName(this.form, 'lieuNaissance');
    let fieldSexe = this.formConfigService.findFieldByName(this.form, 'sexe');

    if (fieldNom) fieldNom.templateOptions.disabled = this.data.nom !== '' ? true : false;
    if (fieldPrenom) fieldPrenom.templateOptions.disabled = this.data.prenom !== '' ? true : false;
    if (fieldDateNaissance) fieldDateNaissance.templateOptions.disabled = this.data.dateNaissance !== '' ? true : false;
    if (fieldLieuNaissance) fieldLieuNaissance.templateOptions.disabled = this.data.lieuNaissance !== '' ? true : false;
    if (fieldSexe) fieldSexe.templateOptions.disabled = this.data.sexe !== '' ? true : false;

  }

  setLoginAsyncValidator() {
    this.emailField.asyncValidators = {
      uniqueLogin: {
        expression: (control: UntypedFormControl) => this.loginValidator(control),
        message: 'L\'adresse e-mail est déjà utilisée',
      }
    };

    this.loginCheckSearch$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(300),
      tap(() => {
        this.loginCheckState = 'loading';
      })
    ).subscribe(searchTerm => {
      if (!this.id && !this.userCreated && !this.isLoading && !this.isSaving) {
        if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
        this.searchSubscription = this.userService.checkLoginAvailability(searchTerm)
          .subscribe(loginIsAvailable => {
            if (!loginIsAvailable) {
              this.loginCheckState = 'ko';
              this.bottomSheet.open(this.loginAlreadyExistQuestionTemplate);
              this.loginCheckRequest$.next(false);
            } else {
              this.loginCheckState = 'ok';
              this.loginCheckRequest$.next(true);
            }
          });
      } else {
        this.loginCheckRequest$.next(true);
      }
    });

  }

  loginValidator(control: UntypedFormControl): Promise<boolean> {
    if (!this.loginCheckLoaderIsMoved) { this.moveLoginCheckLoader(); }
    if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
    this.loginCheckSearch$.next(control.value);
    return this.loginCheckRequest$.pipe(
      first(),
      tap(res => {
        if (res === true) { // valider le champ email manuellement (car le validator formly ne le fait pas toujours bien)
          this.emailField.formControl.setErrors(null);
        }
      })).toPromise();
  }

  moveLoginCheckLoader() {
    const emailFieldElmt = this.emailField.__formField__._elementRef.nativeElement;
    emailFieldElmt.appendChild(this.loginCheckindicator.nativeElement);
    this.loginCheckLoaderIsMoved = true;
  }


  checkAddress() {
    this.addressUpdateService.checkAddress('user').subscribe(data => {
      this.dataUpdateAddress = data;

      if ((data.familyAddress && !data.familyAddressIsSameUserAddress) ||
        (data.conjointAddress && !data.conjointAddressIsSameUserAddress)) {

        if (this.dataUpdateAddress.conjoint) {
          this.conjointData = this.addressUpdateService.extractDataWithoutAdress(data.conjoint);
        }

        this.foyerData = this.addressUpdateService.extractDataWithoutAdress(data.famille);

        this.bottomSheet.open(this.updateAddressBottomSheet);

        this.manyAddressesDisplay = this.addressUpdateService.manyAddressesDisplay(
          data.familyAddress,
          data.conjointAddress,
          data.familyAddressIsSameUserAddress,
          data.conjointAddressIsSameUserAddress)
      }
    });
  }

  validUpdate() {
    if (this.familyService.currentFamily) {

      if (this.updateFamilyChecked === 'oui' && (this.updateConjointChecked === 'non' || !this.updateConjointChecked)) {
        this.addressUpdateService.updateAddressFoyer('user', this.dataUpdateAddress);
      }

      if ((this.updateFamilyChecked === 'non' || !this.updateFamilyChecked) && this.updateConjointChecked === 'oui') {
        this.addressUpdateService.updateAddressConjoint('user', this.dataUpdateAddress);
      }

      if (this.updateFamilyChecked === 'oui' && this.updateConjointChecked === 'oui') {
        this.addressUpdateService.updateAddresses('user', this.dataUpdateAddress);
      }
    }
  }

  onSave(event: SubmitFormEvent) {
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';
    if (this.readOnly) {
      return;
    }

    if (!!this.authService.creatingUserFromOIDC) {
      this.data.email = this.authService.creatingUserFromOIDC.userInfos.email
      this.data.sub = this.authService.creatingUserFromOIDC.userInfos.sub
      this.data.passwordGroup = "no_password_because_oidc_creation"
    }

    this.isSaving = true; // Hide the form while we send data
    const saveMethod = (this.id) ? this.userService.update(this.data, this.id, this.step) : this.userService.create(this.data);
    saveMethod.subscribe((response: any) => {

      this.helperService.displayDebugTraces(response.traces);
      this.helperService.notifySuccess('', response.messages);

      if (this.id) {
        if (this.step === "coordinates" && this.familyService.currentFamily) {
          this.checkAddress();
        }
        if (!this.fromDemarche) {
          this.location.back();
        } else {
          this.onValidate.emit({ id: this.userService.currentUser.idAdulte, type: 'Adulte' });
          this.isSaving = false;
        }
      } else if (!this.id) {
        if (response && response.user) {
          // User was successfully created, inform the user and login auto or redirect to login
          this.userCreated = true;
          sessionStorage.removeItem('creatingUser')

          this.snackBar.info('Compte créé')
          if (response.user.token) {
            // Store user details (token, etc) to keep logged between pages
            this.authService.updateUser(response.user as User);
            setTimeout(() => {
              this.demarcheService.getConfig().subscribe(conf => {
                if (conf.idDemarcheShowAfterCreateAccount) {
                  this.router.navigate([`account/demarches/${conf.idDemarcheShowAfterCreateAccount}`])
                } else {
                  this.router.navigate(['/account']);
                }
              });
            }, 200);
          }
        } else {
          this.isSaving = false;
          this.snackBar.error(`Il semblerait que quelque chose se soit mal passé ... La requête n'a pas renvoyé d'utilisateur`)
          setTimeout(() => { this.router.navigate(['/home']); }, 200);
        }
      }
    }, err => {
      this.isSaving = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isSaving')...
        this.helperService.manageServerError(err, this.formViewer);
      })

    });
  }

  goLogin() {
    this.router.navigate(['/login', { email: this.data.email }], { skipLocationChange: true });
  }

  goForgotPassword() {
    this.router.navigate(['/forgot-password', { email: this.data.email }], { skipLocationChange: true });
  }

  onStepChange(indice) {
    this.actualStep = this.form[indice];
  }

  get externalAuthIsVisible() {
    return !!this.externalAuth && this.externalAuth?.isActive && !this.isLoading && !this.id && this.actualStep && this.actualStep.stepName === 'connexion'
  }

  stepperOrientationChanged(stepperOrientation) {
    setTimeout(() => { // prevent NG0100: ExpressionChangedAfterItHasBeenCheckedError 
      this.stepperOrientation = stepperOrientation;
    })
  }
}
