<!-- Error message display  -->
<mat-card *ngIf="errorMessage!=''" class="error-container" [ngClass]="{'small-width':stepperOrientation==='vertical' }">
  <mat-card-title class="mat-error">
    <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
    <span>Erreur</span>
    <button mat-icon-button aria-label="dismiss" (click)="errorMessage='';" style="float:right;">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content>
    <pre [innerHTML]="errorMessage | safe" class="mat-error" style="text-align: left;"></pre>
  </mat-card-content>
</mat-card>


<div id="form" [ngClass]="(formFields.length > 1)?'form-with-steps':'single-step-form'">
  <!-- Here we listen to enter.key event to prevent default submit if we're not
    on the last step of a form, instead it will validate and go next step -->
  <form [formGroup]="form" (keydown.enter)="onEnter($event)" (ngSubmit)="onSubmit()">

    <mat-stepper [ngClass]="{'disabled-stepper-header': isLoading}" [orientation]="stepperOrientation"
      [(selectedIndex)]="activedStep" (selectionChange)="onStepChange($event)" [linear]="!admin">

      <ng-container *ngFor="let formStep of formFields; let index = index; let last = last;">

        <mat-step *ngIf="formStep?.enabled" [stepControl]="form.at(index)">

          <ng-template matStepLabel>{{formStep?.label}}</ng-template>

          <div [ngStyle]="{'display': isLoading || formStep?.old ? 'none' : ''}">
            <!-- don't use ngIf here because it remove the formly component, and can't update the conf after "programme perso" -->

            <ng-container *ngIf="stepperOrientation==='horizontal'">
              <ng-container [ngTemplateOutlet]="horizontalDisplay"> </ng-container>
            </ng-container>

            <ng-container *ngIf="stepperOrientation==='vertical'">
              <ng-container [ngTemplateOutlet]="verticalDisplay"> </ng-container>
            </ng-container>

          </div>

          <ng-container [ngTemplateOutlet]="loader" *ngIf="isLoading || formStep?.old">
          </ng-container>

          <!-- Template horizontal -->
          <ng-template #horizontalDisplay>
            <mat-card class="form-step-card" [ngStyle]="{'display': !isLoading && !formStep?.old ? '' : 'none'}">
              <mat-card-header>
                <mat-icon mat-card-avatar class="icon">assignment</mat-icon>
                <mat-card-title>{{ formStep.label }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <ng-container [ngTemplateOutlet]="formly"></ng-container>
              </mat-card-content>
              <mat-divider></mat-divider>
              <mat-card-actions style="padding:12px;" class="fx-direction-row fx-center-center">
                <ng-container [ngTemplateOutlet]="buttons"></ng-container>
              </mat-card-actions>
            </mat-card>
          </ng-template>

          <!-- Template vertical -->
          <ng-template #verticalDisplay>
            <div [ngStyle]="{'display': !isLoading &&  !formStep?.old ? '' : 'none'}" class="small-width-container">
              <h2 class="mat-title" *ngIf="formFields.length === 1">
                <mat-icon mat-card-avatar class="icon">assignment</mat-icon>
                <span>{{ formStep.label }}</span>
              </h2>

              <ng-container [ngTemplateOutlet]="formly"></ng-container>
              <mat-divider></mat-divider>
              <div [ngClass]="{'text-center': formFields.length === 1}">
                <ng-container [ngTemplateOutlet]="buttons"></ng-container>
              </div>

            </div>
          </ng-template>

          <!-- Le formulaire formly   -->
          <ng-template #formly>
            <formly-form [form]="form.at(index)" [model]="model" [options]="options[index]" [fields]="formStep.fields"
              (modelChange)="onModelChange($event)"></formly-form>
          </ng-template>

          <!-- Template buttons  -->
          <ng-template #buttons>
            <button *ngIf="index !== 0" matStepperPrevious mat-raised-button color="primary" type="button">
              <mat-icon>arrow_back</mat-icon> Retour
            </button>
            <button *ngIf="!last" matStepperNext mat-raised-button color="primary" type="button"
              (click)="validate(index)" [disabled]="suspendSubmit">
              Suivant <mat-icon>arrow_forward</mat-icon>
            </button>
            <button *ngIf="!admin && last && !readOnly" mat-raised-button color="primary"
              [disabled]="suspendSubmit || (!form.valid && !form.untouched)">
              <mat-icon>check</mat-icon> Valider
            </button>
          </ng-template>

        </mat-step>

      </ng-container><!-- fin de la boucle for -->

    </mat-stepper>

  </form>
</div>


<!-- Template : loader -->
<ng-template #loader>
  <div style="width: 100%; height: 100%; min-height: 40vh; display:flex;">
    <app-loader message="Chargement..." style="text-align: center;">
    </app-loader>
  </div>
</ng-template>
