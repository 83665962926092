import { Injectable, TemplateRef } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BoardConfig } from '@app/models/board-card';
import { BannerConfig } from '@app/models/global-config';
import { HeaderConfig } from '@app/models/banner';
import { AppRoute } from '@app/models/nav-item';
import { BehaviorSubject, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalConfigService, BaseConfigService, SnackbarService, AuthenticationService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private currentBannerSubject = new BehaviorSubject<BannerConfig>(null);
  currentBanner$ = this.currentBannerSubject.asObservable();

  private faviconSubject = new BehaviorSubject<string>(null);
  favicon$ = this.faviconSubject.asObservable();

  private pageTitleSubject = new BehaviorSubject<string>(null);
  pageTitle$ = this.pageTitleSubject.asObservable();

  private breadcrumbsSubject = new BehaviorSubject<AppRoute[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  private inAdminSubject = new BehaviorSubject<boolean>(false);
  inAdmin$ = this.inAdminSubject.asObservable();
  private isAdminRoute: boolean;

  // topbarConfig$ = new BehaviorSubject<TopbarConfig>(null);
  topbarActionsTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);

  private canonicalHeaderLink = null;
  private accountBoard: BoardConfig;

  constructor(
    private configService: GlobalConfigService,
    private platformLocation: PlatformLocation,
    private authService: AuthenticationService,
    private baseConfigService: BaseConfigService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,


  ) {

    // Titles & Breadcrumbs refresh management 
    setTimeout(() => { // In a setTimeout to be sure this is not triggered before baseConfigService has loaded firstLoadConfigs
      this.authService.accountType$.subscribe(type => {
        if (type === 'family' || type === 'assmat') {
          this.accountBoard = type === 'family' ? this.baseConfigService.getFirstConf("board-user").content : this.baseConfigService.getFirstConf("board-assmat").content;
        } else if (type == 'enseignant') {
          this.accountBoard = this.baseConfigService.getFirstConf("board-enseignant").content
        } else if (type === 'noAccount') {
          this.snackbarService.error(this.translateService.instant('login.no_account_err_message'));
        } else {
          this.accountBoard = null;
        }

        this.refreshPageTitle(this.activatedRoute, this.router.url);
        this.refreshBreadcrumbs(this.activatedRoute);
      });

      merge(
        this.translateService.onLangChange,
        this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      ).subscribe(() => {
        this.refreshBreadcrumbs(this.activatedRoute.root);
        this.refreshPageTitle(this.activatedRoute, this.router.url);
      });
    })

  }

  setCurrentPageTitle(title: string, inAdmin: boolean = null) {
    setTimeout(() => {
      this.pageTitleSubject.next(title);

      if (inAdmin !== null) {
        this.isAdminRoute = inAdmin
      }
      this.inAdminSubject.next(this.isAdminRoute);
    });
  }

  setBanner(banner: BannerConfig) {
    this.currentBannerSubject.next(banner);
  }

  setFavicon(url: string) {
    this.faviconSubject.next(url);
  }

  setBreadcrumbs(breadcrumbs: AppRoute[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  refreshBreadcrumbs(route: ActivatedRoute) {
    this.setBreadcrumbs([{ title: 'menu.home', url: '/', icon: 'home' } as AppRoute].concat(this.createBreadcrumbs(route)));
  }

  createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: AppRoute[] = []): AppRoute[] {
    const children = route.children;

    if (!children?.length) {
      return breadcrumbs;
    }

    for (const child of children) {
      const path = child.snapshot.url.map(part => part.path).join('/');

      if (path && path !== 'home') {
        url += '/' + path;
        const title = child.snapshot.data.title || ('route.' + path.replace(/\//g, '_'));
        breadcrumbs.push({ title, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  refreshPageTitle(route: ActivatedRoute, url: string) {
    const title = this.computePageTitle(route, url);
    this.setCurrentPageTitle(title);
    this.setCurrentCanonicalURL()
  }

  computePageTitle(route: ActivatedRoute, url: string) {
    const urlParts = url.split('/').filter(p => !!p);
    this.isAdminRoute = urlParts.indexOf('admin') === 0;

    if (this.accountBoard) { // means that we are on user side (family or assmat)
      // remove empty char before leading /

      if (urlParts.length > 1 && urlParts[0] === 'account') {
        let sectionName = urlParts[1];

        if (sectionName === 'conjoint') {
          sectionName = 'foyer';
        }

        const pageCard = this.accountBoard.cards.find(c => c.component === sectionName);
        if (pageCard) {
          return pageCard.title;
        }
      }
    }

    const routeTitle: string = this.getRouteTitle(route);
    if (routeTitle && routeTitle != '') {
      return routeTitle;
    }

    // const routeData = this.getRouteData(route);
    // if (routeData?.title) {
    //   return routeData.title;
    // }

    // const titleFromRouteArray = routeTitleArray.find(r => r.url === url);
    // if (titleFromRouteArray) {
    //   return titleFromRouteArray.title;
    // }

    // Title not found => display Main title 
    const headerConfig = this.configService.currentPartValue('header') as HeaderConfig
    return headerConfig?.title?.text || 'Portail Famille';

  }

  // getRouteData(route: ActivatedRoute) {
  //   while (route.firstChild) {
  //     route = route.firstChild;
  //   }

  //   // use this to get only data from current route, otherwise they're inherited by default (avoids empty title, why not ...)
  //   // return route?.routeConfig?.data;
  //   return route.snapshot.data;
  // }


  getRouteTitle(route: ActivatedRoute): string {
    // As we are not in a compoment, we receive the 'root' route, so we need to parse
    //  the route tree until the last child to get the 'real' activated route (of the loaded component).
    // For admin routes, we want to retrieve the same hierarchy that is stored in translation, 
    //  so we build the title as 'category_title.links.component_title'
    // For other routes, we juste take the 'component_title' 
    let title: string[] = [];
    let afterAdminPath = false;

    while (route.firstChild) {
      route = route.firstChild;

      if (this.isAdminRoute) {
        /// Build the title from routes tree 
        const routeConfig = route.snapshot.routeConfig || null
        if (!afterAdminPath && routeConfig?.path === 'admin') {
          afterAdminPath = true;
          title.push('admin_board')
        } else {
          const routeData = routeConfig?.data || null
          if (routeData?.title) {
            title.push(routeData.title)
            if (!!routeConfig.children)
              title.push('links')
          }
        }
      }
    }
    if (!this.isAdminRoute) {
      title.push(route.snapshot.data?.title);
    }

    return title.join(".");
  }

  // setTopbarActions(actions: PageAction[]) {
  //   const config = this.topbarConfig$.value || {};
  //   config.actions = actions;

  //   this.topbarConfig$.next(config);
  // }

  resetTopbar() {
    // this.topbarConfig$.next(null);
    this.topbarActionsTemplate$.next(null);
  }

  setCurrentCanonicalURL() {
    // To avoid google to detect duplicate url from many customers portals
    // see : https://developers.google.com/search/docs/crawling-indexing/consolidate-duplicate-urls?hl=fr
    // `<link rel="canonical" href="${url}" />`

    if (!this.canonicalHeaderLink) {
      this.canonicalHeaderLink = document.querySelector('link[rel="canonical"]');
    }
    let found = !!this.canonicalHeaderLink;
    const absoluteUrl = (this.platformLocation as any).location.href;

    if (!found) {
      this.canonicalHeaderLink = document.createElement('link');
      // this.canonicalHeaderLink.id = 'canonicalLink';
      this.canonicalHeaderLink.setAttribute('rel', 'canonical');
    }

    this.canonicalHeaderLink.setAttribute('href', absoluteUrl)

    if (!found) {
      document.head.append(this.canonicalHeaderLink);
    }


  }

}
