import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MenuService } from '@app/services/menu.service';
import { AuthenticationService, GlobalConfigService, UserService, FamilyService, SnackbarService, TypeSnackbar, PermissionService, ThemeService } from '@app/services';
import { PlatformService } from '@app/services/platform.service';
import { HeaderConfig } from '@app/models/banner';
import { NavItem } from '@app/models/nav-item';
import { Role, User } from '@app/models/user';
import { forkJoin, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Adulte } from '@app/models/adulte';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { OpenIdConfig } from '@app/models/authentification-config';
import { MessagerieService } from '@app/services/messagerie.service';
import moment from 'moment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() compact = false;
  @Input() previewMode = false;
  @Input() items: NavItem[];

  headerConfig: HeaderConfig;
  themeSwitchEnabled: boolean;

  accountSwitchDisabled: boolean;
  account: 'assmat' | 'enseignant' | number;
  enabledAssmatAccount: boolean;
  enabledEnseignantAccount: boolean;

  currentUser: User;
  currentAdulte: Adulte;

  externalAuth: OpenIdConfig;

  env = environment;
  changelogDisplayable = false;
  markedLoaded = false;
  marked: any;
  isAdmin = false;
  countMessagesUnread = 0
  shouldCountMessages = false;
  isUserTypeFamily = false;

  onDestroy$ = new Subject();

  constructor(
    private userService: UserService,
    private familyService: FamilyService,
    private activatedRoute: ActivatedRoute,
    private snackbarService: SnackbarService,
    private authenticationService: AuthenticationService,
    private themeService: ThemeService,
    private dialog: MatDialog,

    public globalConfigService: GlobalConfigService,
    public platformService: PlatformService,
    public menuService: MenuService,
    public permService: PermissionService,
    public router: Router,
    public messagerieService: MessagerieService
  ) { }

  ngOnInit() {
    this.enabledAssmatAccount = this.permService.hasPermission('enabled_assmat_account');
    this.enabledEnseignantAccount = this.permService.hasPermission('enabled_enseignant_account');

    if (!this.previewMode) {
      this.menuService.initMenuItems();
    }

    this.globalConfigService.getPart('header').subscribe((conf: HeaderConfig) => this.headerConfig = conf || new HeaderConfig());
    this.authenticationService.getExternalAuthConf().subscribe((conf) => this.externalAuth = conf || ({} as OpenIdConfig));

    this.themeService.darkModeSwitchEnabled$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(x => this.themeSwitchEnabled = x);

    this.authenticationService.currentUser$.subscribe(data => {
      this.currentUser = data;

      if (this.currentUser?.accountType === 'assmat' && this.enabledAssmatAccount) {
        this.account = 'assmat';
      }
      if (this.currentUser?.accountType === "family") {
        this.isUserTypeFamily = true;
        this.getUnreadMessages();
        this.familyService.currentFamilyReadyOnce$.subscribe(f => {
          this.account = f.id;
        });
      }
      if (this.currentUser?.accountType === 'enseignant' && this.enabledEnseignantAccount) {
        this.account = 'enseignant';
      }

      if (this.authenticationService.hasRole(Role.Manager)) {
        this.changelogDisplayable = true;
        if (!this.markedLoaded) {
          // Pour le formatage du MarkDown
          import('marked').then(m => {
            this.marked = m.parse;
            this.markedLoaded = true;
          });
        }
      }

    });

    this.isAdmin = this.authenticationService.hasRole(Role.Admin);
    this.menuService.menuItems$.subscribe(items => {
      if (items.length && items.find(item => item.label == "messagerie" && item.enabled)) {
        this.shouldCountMessages = true
        this.getUnreadMessages()
      } else {
        this.shouldCountMessages = false
      }
    })
    this.userService.currentAdulte$.subscribe(data => this.currentAdulte = data);

    if (!this.compact) {
      this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(true)
      ).subscribe(_ => this.accountSwitchDisabled = this.isRouteAccountSwitchDisabled(this.activatedRoute));
    }
  }

  getUnreadMessages() {
    if (this.isUserTypeFamily && this.shouldCountMessages) {
      this.messagerieService.getUnreadMessages().subscribe(res => this.countMessagesUnread = res)
      this.shouldCountMessages = false;
    }

  }

  showToUser(item: NavItem) {
    const logged = this.authenticationService.isAuthenticated;
    return !item.limited || (item.limited === 'login' && logged) || (item.limited === 'logout' && !logged);
  }

  showDependOnRole(item: NavItem) {
    return !item.role || (item.role === "admin" && this.isAdmin) || (item.role === "user" && !this.isAdmin);
  }

  // /!\ Recursive call ! (inspired of AppComponent Router.events listener)
  isRouteAccountSwitchDisabled(route: ActivatedRoute) {
    const conf = route.routeConfig;

    if (conf && conf.data && conf.data.disableAccountSwitch) {
      return true;
    }

    return route.firstChild ? this.isRouteAccountSwitchDisabled(route.firstChild) : false;
  }


  onMenuLinkClick() {
    this.getUnreadMessages()
    if (this.platformService.isMobile) {
      this.menuService.sidenav.close();
    }
  }

  onCustomItemClick(item: NavItem, event: MouseEvent) {
    event.preventDefault();

    if (this.previewMode) {
      this.snackbarService.open({
        message: 'Lien inactif pendant la configuration',
        type: TypeSnackbar.info,
        textButton: 'Ok'
      });
      return false;
    }

    if (this.platformService.isMobile) {
      this.menuService.sidenav.close();
    }

    if (item.linkUrlApiDoc) {
      window.open(environment.apiUrl + item.linkUrlApiDoc, item.target);
    } else if (item.urlContent) {
      this.router.navigate([item.urlContent]);
    } else {
      window.open(item.linkUrl, item.target);
    }
  }

  onChangeAccountType(value: string | number) {
    if (value === 'assmat') {
      this.authenticationService.setAccountType(value);
    } else if (typeof (value) === 'number') {
      this.authenticationService.setAccountType('family');
      this.familyService.setCurrentFamily(this.currentAdulte.families.find(f => f.id === value));
    } else if (value === 'enseignant') {
      this.authenticationService.setAccountType(value);
    } else
      this.router.navigate(['account']);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  showChangelog() {
    if (this.changelogDisplayable) {
      forkJoin([this.menuService.getNumVersion(), this.menuService.getChangelog()])
        .subscribe(([version, changeLogMD]) => {

          const dateApiPortail = moment(version.datePortail).format('DD/MM/Y - HH:mm')
          const dateDomino = moment(version.dateDomino).format('DD/MM/Y - HH:mm')

          const content = `
### Versions :
- Portail : **${this.env.version}**
- API Portail : **${version.numVersionPortail}**  _(${dateApiPortail})_
- Domino : **${version.numVersionDomino}** _(${dateDomino})_
---
${changeLogMD}
          `

          console.log(content)

          const html = this.marked(content);
          this.dialog.open(HelpDialogComponent, {
            data: { title: 'Changements', message: html }
          })
        });
    }
  }

  get userNameTooltip() {
    if (this.currentUser.fromOidc) {
      return `
        Vous êtes identifié grâce à ${this.currentUser?.oidcProvider} : ${this.currentUser.username}
      `
    } else {
      return `
        Connecté en tant que : 
        ${this.currentUser.username}
      `;
    }
  }

  getLink(item) {
    if (item.label == "messagerie" && !this.isAdmin) {
      return "/account/messagerie"
    }
    return item.link
  }
}
