import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JourCours, PointageEnseignantConfig, PresenceAbsence } from '@app/models/pointage-enseignant';
import moment from 'moment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { DATE_FORMAT } from './planning.service';
import { PointageRubrique } from '@app/models/pointage';

export const FAKE_JOURCOURS: JourCours[] = [{
  date: moment(new Date()).format(DATE_FORMAT),
  heureDebut: "19:45:00.000",
  heureFin: "22:15:00.000",
  idCours: 95,
  idJourCours: 0,
  libelle: "Cours test1"
},
{
  date: moment(new Date()).format(DATE_FORMAT),
  heureDebut: "19:45:00.000",
  heureFin: "22:15:00.000",
  idCours: 95,
  idJourCours: 0,
  libelle: "Cours test4"
},
{
  date: moment(new Date()).format(DATE_FORMAT),
  heureDebut: "19:45:00.000",
  heureFin: "22:15:00.000",
  idCours: 95,
  idJourCours: 0,
  libelle: "Cours test5"
},
{
  date: moment(new Date()).format(DATE_FORMAT),
  heureDebut: "19:45:00.000",
  heureFin: "22:15:00.000",
  idCours: 95,
  idJourCours: 0,
  libelle: "Cours test6"
},
{
  date: moment(new Date()).add(7, 'd').format(DATE_FORMAT),
  heureDebut: "17:45:00.000",
  heureFin: "18:15:00.000",
  idCours: 96,
  idJourCours: 0,
  libelle: "Cours test2"
},
{
  date: moment(new Date()).add(1, 'M').format(DATE_FORMAT),
  heureDebut: "11:45:00.000",
  heureFin: "12:15:00.000",
  idCours: 97,
  idJourCours: 0,
  libelle: "Cours test3"
}]

@Injectable({
  providedIn: 'root'
})
export class EnseignantService {
  url = 'pointage/enseignant'
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private coursSessionListCache$: Observable<any>;
  private coursListCache$: Observable<any>;

  constructor(protected http: HttpClient,) { }

  getCoursList(idEnseignant): Observable<any> {
    if (!this.coursListCache$) {
      this.coursListCache$ = this.http.get(`${this.url}/listCours?idEnseignant=${idEnseignant}`, this.httpOptions)
        .pipe(shareReplay(1));
    }
    return this.coursListCache$
  }

  getCoursSessionList(idEnseignant: number) {
    let params = new HttpParams();
    params = params.append('idEnseignant', idEnseignant);

    if (!this.coursSessionListCache$) {
      this.coursSessionListCache$ = this.http.get(`${this.url}/listCoursSession`, { ...this.httpOptions, params })
        .pipe(shareReplay(1));
    }

    return this.coursSessionListCache$;
  }

  getJourCours(idJourCour: number, idEnseigant: number) {
    return this.http.get(`${this.url}/${idEnseigant}/jourCours/${idJourCour}`, this.httpOptions);
  }

  getListStudents(idCours: number, idJourCours: number, idEnseignant: number) {
    let params = new HttpParams();
    params = params.append("idCours", idCours);
    params = params.append("idJourCours", idJourCours);
    params = params.append("idEnseignant", idEnseignant)
    return this.http.get(`${this.url}/listStudents`, { ...this.httpOptions, params })
  }

  getPresence(idCours: number, date: string, idJourCour: number, idEnseignant: number) {
    let params = new HttpParams();
    params = params.append("idCours", idCours);
    params = params.append("date", date);
    params = params.append("idJourCours", idJourCour);
    params = params.append("idEnseignant", idEnseignant);

    return this.http.get(`${this.url}/presenceAbsence`, { ...this.httpOptions, params })
  }

  getRubriques() {
    return this.http.get<PointageRubrique[]>(`pointage/rubriquesLasido`);
  }

  savePresencesAbsences(presencesAbsences: PresenceAbsence[], idEnseignant: number) {
    return this.http.post(`pointage/enseignant/save`, { presencesAbsences, idEnseignant });
  }

  isEditableDate(date: string | Date, config: PointageEnseignantConfig): boolean {
    const nbDaysLimiteSaisie = config.nbJourLimiteSaisie;
    const firstEditableDate = moment().subtract(nbDaysLimiteSaisie, 'd').format(DATE_FORMAT);

    if (!config.calendar.editabledAfterCurrentDay) {
      return firstEditableDate <= date && !(date > moment().format(DATE_FORMAT));
    }

    return firstEditableDate <= date;
  }
}

