<div class="demarches-container" *ngIf="demarches; else loader">

  <div *ngIf="demarches.length" style="text-align: center;">
    <h2 class="primary">{{'demarches.demarches_available'|trans }}</h2>
  </div>

  <div *ngFor="let demarche of demarches">
    <div (click)="onClickDemarche(demarche)" class="demarche-item">
      <div>
        <ng-container *ngTemplateOutlet="showIcon; context: {data: demarche}"></ng-container>
        <span>{{demarche.label}}</span>
      </div>
      <mat-icon color="primary" style="margin-left: 3px;">arrow_right_alt</mat-icon>
    </div>
  </div>

  <div *ngIf="!demarches.length" style="text-align: center;">
    <h2>{{'demarches.no_demarches'|trans }}</h2>
  </div>
</div>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>

<ng-template #showIcon let-data="data">
  <mat-icon *ngIf="data.icon && !data.iconDomino && !data.iconFontawesome" class="main-icon">{{ data.icon }}
  </mat-icon>
  <app-icon *ngIf="data.iconFontawesome || data.iconDomino"
    [icon]="data.iconFontawesome ? data.iconFontawesome : data.iconDomino" [type]="
      data.iconDomino
        ? 'do'
        : !data.typeIcon && data.iconFontawesome
        ? 'fa'
        : data.typeIcon
    " [size]="24" style="margin-right: 12px">
  </app-icon>
</ng-template>
