import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Usager } from '@app/models/consumer';
import { DemarcheItem, DemarcheStep, ReponseStepQuestion } from '@app/models/demarche';
import { GedEntity } from '@app/models/ged';
import { PermissionService, PlatformService, SnackbarService } from '@app/services';
import { DemarcheService } from '@app/services/demarche.service';
import { HeaderService } from '@app/services/header.service';
import { clone } from '@app/utils/object';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-demarche',
  templateUrl: './demarche.component.html',
  styleUrls: ['./demarche.component.scss']
})
export class DemarcheComponent implements OnInit {

  demarche: DemarcheItem;
  stepperOrientation: Observable<StepperOrientation>;
  usager: Usager;
  gedEntite: GedEntity;
  addDocumentPermission: boolean;
  demarcheCompleted: boolean;

  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private headerService: HeaderService,
    private demarcheService: DemarcheService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private permissionService: PermissionService,
    public platformService: PlatformService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    if (history.state.demarcheString) {
      this.demarche = JSON.parse(history.state.demarcheString);
    }
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.idDemarche;
    this.addDocumentPermission = this.permissionService.hasPermission('document_add');

    if (this.demarche) {
      setTimeout(() => {
        this.stepper.selectedIndex = history.state.stepIndex;
      }, 500);
    } else {
      this.demarcheService.getDemarcheById(id).subscribe(demarche => {
        this.demarche = clone(demarche);

        if (!this.demarche) {
          this.router.navigate(['account/demarches']);
        } else {
          if (this.addDocumentPermission) {
            const newSteps: DemarcheStep[] = [];
            this.demarche.steps.forEach(step => {
              newSteps.push(step);
              if (step.docPieceStep) {
                newSteps.push(step.docPieceStep);
              }
            });
            this.demarche.steps = newSteps;
          }
          this.headerService.setCurrentPageTitle(this.demarche.label);
        }
      })
    }
  }

  getUsagerSelected(event, step: DemarcheStep, index: number) {
    this.usager = event;
    this.gedEntite = { id: this.usager.id, type: this.usager.type }
  }

  stepSelectionChange(event: StepperSelectionEvent) {

  }

  onValidateStepForm(event, step: DemarcheStep) {

    this.gedEntite = { id: event.id, type: event.type }
    if (event) {
      // step.completed = true;
      // step.editable = false;

      this.afterValidateStep();
    }
  }

  onValidateQuestion(event: any, step: DemarcheStep, index: number) {
    const idReponse = event;
    const reponse = step.reponses.find(reponse => reponse.id === idReponse);

    if (idReponse && reponse?.steps.length) {
      this.handleResponseUpdate(reponse);
      this.insertSteps(reponse, step, index);

    } else {
      this.handleNoMoreSteps(index);
    }

    //   // step.completed = true;
    //   // step.editable = false;


    //   // setTimeout(() => {
    //   //   this.afterValidateStep();
    //   // });
  }

  handleResponseUpdate(reponse: ReponseStepQuestion) {
    if (this.addDocumentPermission && !reponse.alreadyUpdated) {
      reponse.steps = reponse.steps.flatMap(step =>
        step.docPieceStep ? [step, step.docPieceStep] : [step]
      );
      reponse.alreadyUpdated = true;
    }
  }

  insertSteps(reponse: ReponseStepQuestion, step: DemarcheStep, index: number) {

    if (this.demarche.steps.length == 1) {
      this.demarche.steps.push(...reponse.steps);

      if (reponse.isLoop) {
        this.demarche.steps.push(step);
      }

      setTimeout(() => {
        this.stepper.next();
      });
    } else {
      this.demarche.steps.splice(index + 1, 0, ...reponse.steps);

      if (reponse.isLoop) {
        const newIndex = index + 1 + reponse.steps.length;
        this.demarche.steps.splice(newIndex, 0, step);
      }

      setTimeout(() => {
        this.afterValidateStep();
      });
    }
  }

  handleNoMoreSteps(index: number) {
    const hasMoreSteps = this.demarche.steps.length > index + 1;

    if (!hasMoreSteps) {
      if (this.demarche.messageCompletedDemarche) {
        this.demarcheCompleted = true;
      } else {
        this.router.navigate([`account`]);
      }
    } else {
      setTimeout(() => this.stepper.next());
    }
  }

  onValidatePieceAFournir(event, step: DemarcheStep) {
    if (event) {
      // step.completed = true;
      // step.editable = false;

      this.afterValidateStep();
    }

  }

  afterValidateStep() {
    if (this.demarche?.steps.length == 1 || this.isLastStep()) {

      if (this.demarche.messageCompletedDemarche) {
        this.demarcheCompleted = true;
      } else {
        this.router.navigate([`account`]);
      }

    } else {
      setTimeout(() => {
        this.stepper.next();
      });
    }
  }

  isLastStep(): boolean {
    if (this.stepper) {
      return this.stepper.selectedIndex === this.stepper.steps.length - 1;
    } else false;
  }
}
