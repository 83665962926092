<div *ngIf="!loading && planningData; else loader" #calendarWrapper class="calendar-view-wrapper"
  [ngClass]="{'small-screen': isSmallScreen, 'very-small-screen': isVerySmallScreen , 'large-screen': isLargeScreen}">

  <ng-container *ngIf="!saving; else saveLoader">
    <div class="fx-direction-row fx-end-end"
      style="padding: 3px;margin-right: 6px; position: relative; top: 8px; z-index: 2;">
      <span *ngIf="planningCurrentChanges.length && !saving" [matBadge]="planningCurrentChanges.length"
        matBadgeColor="accent"></span>
    </div>

    <mat-form-field style="display: none;">
      <input matInput [matDatepicker]="picker" (dateChange)="onDateSelected($event.value)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #picker startView="year"></mat-datepicker>
    </mat-form-field>

    <full-calendar #calendar *ngIf="!loading; else loader" [options]="options"></full-calendar>

  </ng-container>

</div>

<ng-template #saveLoader>
  <app-loader class="margin">Enregistrement en cours</app-loader>
</ng-template>

<ng-template #loader>
  <app-loader class="margin">{{ 'reservation.loader.planning'|trans }}</app-loader>
</ng-template>
