import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { StepType, ExtendedFormlyFormOptions } from '@app/forms/config/form-model';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { PlatformService } from '@app/services/platform.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HeaderService } from '@app/services/header.service';
import { FamilyService, FormConfigService, FormHelperService, UserService } from '@app/services';
import { FormType } from '@app/models/global-config';
import { ProgrammePersoService } from '@app/services/programme-perso.service';

export interface SubmitFormEvent {
  model: object | any;
  form: UntypedFormArray;
}

@Component({
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss']
})
export class FormViewerComponent implements OnInit, OnChanges {

  @Input() activedStep = 0;

  @Input() formFields: StepType[];
  @Input() admin = false;
  @Input() model: object | any = {};
  @Input() readOnly = false;
  @Input() formTitle: string;
  @Input() typeForm: FormType;

  @Input() containerWidth = this.platformService.mainWidth(); // for preview in Admin mode
  @Input() verticalStepperBeakpoint = 800;

  stepperOrientation: StepperOrientation;

  @Output() save = new EventEmitter();
  @Output() stepChange = new EventEmitter();
  @Output() modelChange = new EventEmitter();

  @Output() containerWidthChange = new EventEmitter<number>();
  @Output() stepperOrientationChange = new EventEmitter<StepperOrientation>();

  form: UntypedFormArray;
  options: ExtendedFormlyFormOptions[];
  errorMessage = '';
  isLoading = false;
  previousStep = null;

  @ViewChild(MatStepper) stepper: MatStepper;

  // Suspend submit while form init, to avoid too early validation, and error "Expression has changed after it was checked"
  suspendSubmit = true;

  constructor(
    private headerService: HeaderService,
    private ProgrammePersoService: ProgrammePersoService,
    private formConfigService: FormConfigService,
    public platformService: PlatformService,
    public router: Router,
    private helperService: FormHelperService,
    private userService: UserService,
    private familyService: FamilyService,
  ) { }

  ngOnInit() {
    this.initForm();
    if (!this.admin) {
      this.platformService.mainWidth$.pipe(
        tap(mainWidth => {
          this.containerWidth = mainWidth;
          this.onContainerWidthChange();
        })).subscribe();
    }
    this.onContainerWidthChange()

  }

  onContainerWidthChange() {
    this.containerWidthChange.emit(this.containerWidth);
    this.setStepperOrientation();
  }

  setStepperOrientation() {
    this.stepperOrientation = this.containerWidth < this.verticalStepperBeakpoint ? 'vertical' : 'horizontal';
    this.stepperOrientationChange.emit(this.stepperOrientation)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formTitle?.currentValue) {
      this.headerService.setCurrentPageTitle(this.formTitle);
    }
  }

  initForm() {
    this.form = new UntypedFormArray(this.formFields.map(_ => new UntypedFormGroup({})));
    this.options = this.formFields.map((step) => {
      return ({
        formState: {
          disabled: this.readOnly,
          selectOptionsData: step.selectOptionsData || null
        },
        containerWidth: this.containerWidth,
        adminMode: this.admin
      } as ExtendedFormlyFormOptions);
    });

    if (!this.model) { this.model = { modeCreation: true }; }

    if (this.readOnly) {
      setTimeout(() => this.form.disable());
    } else {
      setTimeout(() => {
        this.suspendSubmit = false;

        if (!this.model.modeCreation) {
          const currentStep = this.stepper.selectedIndex;
          this.validate(currentStep)
        }

      });
    }

    //trie les options des champs de type 'select'
    this.formConfigService.sortOptionsInSelectFields(this.formFields);

  }

  onEnter(event: KeyboardEvent) {

    if (this.readOnly) {
      return;
    }

    // @TODO: this diserves a clean ...
    if (this.formFields.length === 1) {
      this.validate(0);
      return;
    }

    const target = event.target as HTMLElement;

    if (target.localName === 'textarea') {
      return;
    }

    event.preventDefault();

    const currentStep = this.stepper.selectedIndex;
    this.validate(currentStep); // @TODO: fix, doesn't work on last step .. ?

    if (currentStep === this.formFields.length - 1) {
      return;
    }

    this.stepper.next();
  }

  // Mark all fields as "touched" to notice the user of fields with error, even ones he didn't touch (mostly required)
  validate(index) {
    if (this.admin) {
      return;
    }

    if (!this.form.at(index).valid) {
      this.markAllTouched(this.form.at(index) as UntypedFormGroup);
    }
  }

  markAllTouched(formGroup: UntypedFormGroup | UntypedFormArray) {

    const controls = formGroup instanceof UntypedFormArray ? formGroup.controls : Object.keys(formGroup.controls).map(k => formGroup.controls[k]);

    controls.forEach(control => {
      control.markAsTouched();

      if ((control instanceof UntypedFormGroup || control instanceof UntypedFormArray) && control.controls) {
        this.markAllTouched(control);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.save.emit({ model: this.model, form: this.form } as SubmitFormEvent);
    }
  }

  reset() {
    this.form.reset();
    this.form.clear();
  }

  onStepChange($event) {
    this.previousStep = this.activedStep;
    this.activedStep = $event.selectedIndex;

    if ($event.previouslySelectedIndex < $event.selectedIndex) { // uniquement lorsqu'on avance d'une étape
      this.runProgramsOnDisplay().subscribe((result: any) => {
        if (!!result) {
          this.handleReturnProgramOnDisplay(result, true)
        }
        this.stepChange.emit(this.activedStep);
      })
    } else {
      this.stepChange.emit(this.activedStep);
    }

  }

  runProgramsOnDisplay(): Observable<any> {
    if (!this.admin) {

      const step = this.formFields[this.activedStep];

      if (step?.programOnDisplay?.length) {
        this.isLoading = true;
        step.old = true;

        let idAssmat: number;
        if (this.typeForm === "form-contrat-accueil-mentalo") {
          idAssmat = this.userService.currentUser.idAssmat;
        }

        // Pour certains formulaire nous pouvons avoir besoin de connaitre la famille
        const idFamille = this.familyService.currentFamily?.id

        return this.ProgrammePersoService.executeProgrammePersoForm(this.model, step.stepName, this.typeForm, idFamille, idAssmat)

      }
    }
    return of(false)
  }

  handleReturnProgramOnDisplay(result, updateConf = false) {

    this.helperService.displayDebugTraces(result.traces);
    this.helperService.notifySuccess("", result.messages);

    if (result.errors?.length > 0) {
      this.helperService.manageServerError(result, this)
      // retour à l'étape précedente si possible, mais ce n'est pas possible
      //  - lorsqu'on est en modif puisqu'en modif on est sur une seule étape
      //  - losrqu'on est en création sur la premère étape
      if (this.previousStep !== null) {
        this.activedStep = this.previousStep
      } else {
        setTimeout(() => {
          // en modif => on désactive le bouton valider
          this.suspendSubmit = true;

          // et on ajoute un validator qui renverra toujours une erreur 
          // pour éviter que l'on puisse passer à l'étape suivante 
          // (pour le cas où on est en création sur la première étape)
          this.form.at(this.activedStep).addValidators((): ValidationErrors => {
            return { progError: true } as ValidationErrors
          })
          this.form.at(this.activedStep).updateValueAndValidity()
        });
      }
      this.isLoading = false;

    } else if (updateConf && result.conf) { // => On met à jour la conf de l'étape (uniquement en création puisqu'en modif la conf est déjà chargée à jour)
      let newStep: StepType[];
      newStep = this.formConfigService.getFormView(result.conf).filter(f => f.enabled);
      if (newStep) {
        this.isLoading = false;
        this.formFields[this.activedStep] = newStep[0];

        this.options[this.activedStep] = {
          formState: {
            disabled: this.readOnly,
            selectOptionsData: this.formFields[this.activedStep].selectOptionsData || null
          },
          containerWidth: this.containerWidth,
          adminMode: this.admin
        } as ExtendedFormlyFormOptions;
      }
    }
  }

  onModelChange($event) {
    this.modelChange.emit($event);
  }

  logForm() {
    // this.markAllTouched(this.form)
    console.log('form', this.form);
    console.log('this.model', this.model);
  }

  setErrorMessage(errorMessage) {
    console.log('setErrorMessage', errorMessage)
    setTimeout(() => {
      this.errorMessage = errorMessage;
    });
  }
}
