<div class="container">

  <mat-card *ngIf="config && config.infoMessage" [portailCustomStyle]="config.styleCardInfo">
    <div [innerHtml]="config.infoMessage | safe"></div>
  </mat-card>

  <mat-card *ngIf="(isEdit && preinscription) || options; else loader" style="margin-top: 10px;">
    <mat-card-header class="card-header fx-direction-row fx-center-center">
      <mat-card-title class="fx-direction-row fx-center-center" style="width: 100%;">
        <span *ngIf="!isEdit">Nouvelle pré-inscription</span>
        <span *ngIf="isEdit">{{ preinscription.nom }} {{ preinscription.prenom }}</span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="isEdit">
        <div style="margin-top: 30px;">
          <label><b>{{ 'lasido.date_demande'|trans }} :</b></label>
          <span>{{ preinscription.dateDemande ? (preinscription.dateDemande|date) : '' }}</span>
        </div>

        <div *ngIf="preinscription.accueilName">
          <label><b>Etablissement - Accueil :</b></label>
          <span *ngIf="preinscription.etablissementName">{{ preinscription.etablissementName }} ></span>
          {{ preinscription.accueilName }}
        </div>

        <div>
          <label><b>{{ 'lasido.annee_scolaire'|trans }} : </b></label>
          <span>{{ preinscription.anneeScolaireName }}</span>
        </div>

        <div class="status">
          <label><b>{{ 'lasido.status'|trans }} :</b></label>
          <span>
            {{ preinscription.decision }}

            <app-icon *ngIf="preinscription.decision === 'Refusée'" type="fa" icon="times-circle" class="warn"
              size="16"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Acceptée'" type="fa" icon="check-circle" class="primary"
              size="16"></app-icon>
            <app-icon *ngIf="preinscription.decision === 'Abandon'" type="fa" icon="trash" size="16">
            </app-icon>
            <app-icon *ngIf="preinscription.decision === 'En attente'" type="fa" icon="question-circle" class="accent"
              size="16"></app-icon>
          </span>
        </div>

      </mat-card-subtitle>
    </mat-card-header>
    <div *ngIf="!isEdit"
      [ngClass]="{'select-usager-container': !platformService.isMobile, 'select-usager-container-mobile': platformService.isMobile}">
      <!-- <p>{{ 'lasido.edit.usager_select'|trans }}</p> -->
      <mat-form-field>
        <mat-label>{{ 'entity.usager'|trans }}</mat-label>
        <mat-select [value]="selectedUsager" required panelClass="usager-select"
          (selectionChange)="onChangeUsager($event)" [disabled]="fromDemarche && usager">
          <mat-optgroup>
            {{ 'entity.adults'|trans }}
            <ng-container *ngFor="let usager of options.usagers">
              <mat-option *ngIf="usager.type === 'adulte'" [value]="usager">
                {{ usager.nom }} {{ usager.prenom }}
              </mat-option>
            </ng-container>
          </mat-optgroup>

          <mat-optgroup>
            {{ 'entity.children'|trans }}
            <ng-container *ngFor="let usager of options.usagers">
              <mat-option *ngIf="usager.type === 'enfant'" [value]="usager">
                {{ usager.nom }} {{ usager.prenom }}
                <span *ngIf="usager.age">({{ 'enfant.age'|trans:{age: usager.age.years} }})</span>
              </mat-option>
            </ng-container>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>

      <div *ngIf="!hideAccueil">

        <!-- <p>{{ 'lasido.edit.accueil_select'|trans }}</p> -->

        <mat-form-field *ngIf="!hideEtablissement">
          <mat-label>{{ 'entity.etablissement'|trans }}</mat-label>
          <mat-select [value]="selectedEtab" required (selectionChange)="onChangeEtab($event)">
            <mat-option *ngFor="let etab of options.etablissements" [value]="etab">{{ etab.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="no-hint">
          <mat-label>{{ 'entity.accueil'|trans }}</mat-label>
          <mat-select [value]="selectedAccueil" required (selectionChange)="onChangeAccueil($event)">
            <mat-option *ngFor="let accueil of selectedEtab?.accueils" [value]="accueil">{{ accueil.name }}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>

    <div *ngIf="(preinscription?.idAdulte || preinscription?.idEnfant) && preinscription?.idAccueil"
      class="lasido-groups-container" [ngStyle]="{'width': !platformService.isMobile ? '70%' : '100%'}">

      <ul *ngIf="preinscription.errors?.length" class="error-list">
        <li *ngFor="let err of preinscription.errors">{{ err|trans }}</li>
      </ul>

      <div class="fx-direction-row fx-between-center">
        <h4 class="section-title">
          <mat-icon>checklist</mat-icon>
          {{ 'lasido.edit.choices_title'|trans }}
        </h4>

        <button mat-stroked-button *ngIf="preinscription.choiceGroups.length" color="primary"
          (click)="addChoiceGroup()">
          <mat-icon>add_circle</mat-icon>
          {{ 'lasido.edit.add_choice'|trans }}
        </button>
      </div>

      <ng-container *ngIf="disciplineTree; else loader">
        <div class="fx-direction-row fx-center-center">
          <button *ngIf="!preinscription.choiceGroups.length" mat-stroked-button color="primary"
            (click)="addChoiceGroup()" class="btn-add">
            <mat-icon style="margin-right:10px ;">add_circle</mat-icon>
            <span>{{ 'lasido.edit.add_choice'|trans }}</span>
          </button>
        </div>
        <p class="text-center" *ngIf="preinscription.choiceGroups.length">{{'lasido.edit.order_choices'|trans}}
        </p>
        <mat-accordion multi cdkDropList (cdkDropListDropped)="onDropChoiceGroups($event, preinscription)">
          <mat-expansion-panel
            *ngFor="let group of preinscription.choiceGroups; let indexGroup = index; let last = last"
            cdkDragLockAxis="y" cdkDrag [expanded]="group.isNew && last" class="discipline-drag-item">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="fx-direction-row fx-wrap fx-start-center" style="font-weight: bold;">
                  <span class="ordre-discipline">{{ indexGroup + 1 }}.</span>
                  <span *ngIf="config.afficherDepartement" class="fx-direction-row fx-start-center">
                    <span>{{ group.departmentName }}</span>
                    <mat-icon class="separator">chevron_right</mat-icon>
                  </span>
                  <span class="fx-direction-row fx-start-center">
                    <span>{{ group.disciplineName }}</span>
                    <mat-icon class="separator">chevron_right</mat-icon>
                  </span>
                  <span>{{ group.niveauName }}</span>
                </div>

              </mat-panel-title>
              <div class="fx-direction-row fx-start-center fx-gap-3" style="margin-right: 15px;">
                <button mat-icon-button color="warn"
                  (click)="removeChoiceGroup(group, indexGroup); $event.stopPropagation()"
                  matTooltip="Supprimer la discipline">
                  <mat-icon>delete</mat-icon>
                </button>
                <mat-icon cdkDragHandle (click)="$event.stopPropagation()" matTooltip="Déplacer la discipline"
                  cdkDragHandle color="accent">
                  open_with
                </mat-icon>
              </div>
            </mat-expansion-panel-header>

            <!-- CONTENU -->
            <ng-template matExpansionPanelContent>

              <ul *ngIf="group.errors?.length" class="error-list">
                <li *ngFor="let err of group.errors" class="warn">{{ err|trans }}</li>
              </ul>

              <div *ngIf="group.hasCours" class="courses-container">

                <p class="text-center">{{'lasido.edit.order_courses'|trans}}
                </p>

                <div *ngIf="group.cours?.length" class="courses-list">
                  <div cdkDropList class="courses-drop-list" (cdkDropListDropped)="onDropCourse($event, group)">
                    <div class="course-drag-item" *ngFor="let course of group.cours; let i = index" cdkDragLockAxis="y"
                      cdkDrag>
                      <div class="courses-index-list">
                        <span>{{ i + 1 }}.</span>
                      </div>
                      <span>{{ course.name }}
                        <span *ngIf="!course.provisoire; else coursProvisoire" class="details-cours">
                          (Le {{course.jour}} de
                          {{course.heureDebut}} à
                          {{course.heureFin}})
                        </span>
                        <ng-template #coursProvisoire>
                          <span class="details-cours">({{'lasido.text-cours-provisoire' | trans}})</span>
                        </ng-template>
                      </span>
                      <div class="courses-delete-list">
                        <mat-icon color="warn" [matTooltip]="'action.delete'|trans"
                          (click)="onRemoveCourse(group, course)">
                          delete
                        </mat-icon>
                      </div>
                      <mat-icon color="accent" style="margin-left: 10px;">open_with</mat-icon>
                    </div>
                  </div>
                </div>
              </div>

              <p *ngIf="!group.hasCours" class="text-center">{{ 'lasido.edit.no_course_message'|trans }}</p>

              <div class="text-center" style="margin-top: 10px;">
                <span *ngIf="group.minCours && group.minCours > group.cours?.length; else maxCoursMessage">
                  {{ 'lasido.edit.min_course'|trans:{min: group.minCours} }}
                </span>

                <ng-template #maxCoursMessage>
                  <ng-container *ngIf="group.maxCours">
                    <span *ngIf="group.maxCours <= group.cours?.length; else remainingCours">
                      {{ 'lasido.edit.max_course'|trans:{max: group.maxCours} }}
                    </span>
                    <ng-template #remainingCours>
                      <span>{{ 'lasido.edit.remaining_course'|trans:{nbCours: group.maxCours - group.cours?.length} }}</span>
                    </ng-template>
                  </ng-container>
                </ng-template>
              </div>

              <div *ngIf="group.hasCours" class="fx-direction-row fx-center-center" style="margin-top: 15px">
                <button mat-raised-button color="primary" (click)="addCours(group)"
                  [disabled]="!!group.maxCours && group.cours?.length >= group.maxCours">
                  <mat-icon>school</mat-icon>
                  {{ 'lasido.edit.add_course'|trans }}
                </button>
              </div>

              <mat-form-field *ngIf="config.enabledComment" class="no-hint" style="width: 100%; margin-top: 20px;">
                <mat-label>Commentaire</mat-label>
                <textarea matInput [(ngModel)]="group.comment" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6">
                  </textarea>
              </mat-form-field>
            </ng-template>

          </mat-expansion-panel>
        </mat-accordion>


        <div *ngIf="config.linkType && (config.url !== '0' || config.urlApiDoc !== '0')"
          class="fx-direction-row fx-center-center" style="text-align: center; margin: 20px 5px 20px 5px;">
          <mat-checkbox [(ngModel)]="preinscription.validationReglement">
            J'accepte le règlement intérieur
          </mat-checkbox>
          <button mat-icon-button color="accent" matTooltip="Voir" (click)="viewReglementInterieur()">
            <mat-icon>visibility</mat-icon>
          </button>
        </div>

        <div class="fx-direction-row fx-center-center" style="margin-top: 20px;">
          <button mat-raised-button color="accent" (click)="onValidatePreinscription()"
            [disabled]="(!preinscription.validationReglement && (config.linkType && (config.url !== '0' || config.urlApiDoc !== '0'))) || !isPreinscriptionValid || saving">
            <mat-icon [hidden]="saving">check</mat-icon>
            <app-loader *ngIf="saving" class="icon-loader" size=".75"></app-loader>
            {{ 'action.validate'|trans }}
          </button>

        </div>
      </ng-container>
    </div>
  </mat-card>

</div>


<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>

<ng-template #editChoiceGroupDialog>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
      <span class="title">
        {{ 'lasido.edit.choice_dialog_title'|trans }}
      </span>
      <button class="close-dialog" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div mat-dialog-content class="choice-group-selects">

    <mat-form-field *ngIf="config.afficherDepartement">
      <mat-label>{{ 'lasido.department'|trans }}</mat-label>
      <mat-select #department (selectionChange)="onChangeDepartment($event.value)">
        <mat-option *ngFor="let dep of disciplineTree" [value]="dep.id">{{ dep.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'lasido.discipline'|trans }}</mat-label>
      <mat-select [(ngModel)]="editChoiceGroup.discipline" (ngModelChange)="onChangeDiscipline($event)"
        [disabled]="!disciplinesAvailable && config.afficherDepartement">
        <mat-option *ngFor="let disc of disciplineChoices" [value]="disc.id">{{ disc.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'lasido.level'|trans }}</mat-label>
      <mat-select [(ngModel)]="editChoiceGroup.niveau" (ngModelChange)="onChangeNiveau($event)"
        [disabled]="!disciplinesAvailable && config.afficherDepartement">
        <mat-option *ngFor="let lvl of niveauChoices" [value]="lvl.id">{{ lvl.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="!disciplinesAvailable && config.afficherDepartement" style="text-align: center; margin-bottom: 16px;">
      {{ 'lasido.edit.max_disciplines'|trans:{max: nbDisciplineMaxDepartementSelected, departement: libelleDepartementSelected} }}
    </div>
  </div>

  <div mat-dialog-actions [align]="'right'">

    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{ 'action.cancel'|trans }}
    </button>
    <button mat-raised-button color="primary" mat-dialog-close="true" [disabled]="!editChoiceGroup.niveau">
      <mat-icon>check</mat-icon>
      {{ 'action.validate'|trans }}
    </button>

  </div>

</ng-template>

<ng-template #coursSelectDialog>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <mat-toolbar mat-dialog-title color="primary" cdkDragHandle>
      <span class="title">
        {{ 'lasido.edit.course_dialog_title'|trans }}
      </span>
      <button class="close-dialog" mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div mat-dialog-content class="cours-select">

    <mat-selection-list #coursList (selectionChange)="onCoursSelectChange($event)" class="selection-list-cours">
      <mat-list-option *ngFor="let cours of coursChoices" [value]="cours">
        <div class="label-cours">{{ cours.name }}</div>
        <div *ngIf="!cours.provisoire; else coursProvisoire" class="details-cours">Le {{cours.jour}} de
          {{cours.heureDebut}} à
          {{cours.heureFin}} </div>
        <ng-template #coursProvisoire>
          <div class="details-cours">{{'lasido.text-cours-provisoire' | trans}}</div>
        </ng-template>
      </mat-list-option>
    </mat-selection-list>
    <div *ngIf="!coursChoices.length" style="text-align: center; margin-bottom: 22px;">
      {{ 'lasido.edit.no_course'|trans }}</div>
  </div>

  <div mat-dialog-actions [align]="'right'">
    <button mat-raised-button color="warn" mat-dialog-close>
      <mat-icon>close</mat-icon>
      {{ 'action.cancel'|trans }}
    </button>
    <button mat-raised-button color="primary" (click)="onValidateCoursSelection(coursList)">
      <mat-icon>check</mat-icon>
      {{ 'action.validate'|trans }}
    </button>
  </div>
</ng-template>
