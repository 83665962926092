<ng-template appTopbarActions>
  <div *ngIf="!loading">
    <button mat-mini-fab matTooltip="Options/Légende" color="accent" [matMenuTriggerFor]="moreMenu">
      <mat-icon>settings</mat-icon>
    </button>
  </div>

  <mat-menu #moreMenu="matMenu">
    <div *ngIf="!loading" style="padding: 5px; min-width: 150px;">

      <div style="text-align: center; font-size: 1.2em; font-weight: bold; margin-bottom: 10px;">Options :</div>

      <div class="switch-container" matTooltip="Vue calendrier / liste">
        <mat-icon>calendar_month</mat-icon>
        <mat-slide-toggle style="height: auto; margin: 0 7px;" [checked]="isListView" color="accent"
          (change)="switchView($event);" (click)="$event.stopPropagation()">
        </mat-slide-toggle>
        <mat-icon>list</mat-icon>
      </div>

      <!-- <h3 style="text-align: center;">Légende :</h3> -->

    </div>
  </mat-menu>
</ng-template>

<app-enseignant-planning *ngIf="!loading; else loader" [idEnseignant]=idEnseignant [config]=config
  [coursList]="coursList" [preview]=false [isListView]="isListView">
</app-enseignant-planning>


<ng-template #loader>
  <app-loader class="margin">Chargement en cours...</app-loader>
</ng-template>
