import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedFormlyFieldConfig, StepType } from '@app/forms/config/form-model';
import { AssmatAvailability, AssmatAvailabilityAgrements, AssmatComplement, AssmatForm, AssmatModeAccueil } from '@app/models/ass-mat';
import { FormConfigService, PermissionService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-assmat-info',
  templateUrl: './assmat-info.component.html',
  styleUrls: ['./assmat-info.component.scss']
})
export class AssmatInfoComponent implements OnInit {

  // Data
  idAssmat: number;
  loading: boolean = true;
  assmatData: any;
  modeAccueil: AssmatModeAccueil;
  complement: AssmatComplement;
  modeAccueilList: string[] = [];
  valeursLibres: any[] = [];
  availability: AssmatAvailability[];
  permToEditDisponibilites: boolean;
  permToEditSouhaitsAccueil: boolean;
  listValeursLibres: any[] = [];

  // Step names
  assmatFormSteps: any[];

  constructor(
    private router: Router,
    private userService: UserService,
    private formConfigService: FormConfigService,
    private assmatService: AssmatService,
    private permService: PermissionService
  ) { }

  ngOnInit(): void {
    this.permToEditSouhaitsAccueil = this.permService.hasPermission('informations_assmat_edit');
    this.permToEditDisponibilites = this.permService.hasPermission('disponibilites_edit');
    this.idAssmat = this.userService.currentUser.idAssmat || null;

    forkJoin([
      this.assmatService.getAssmatById(this.idAssmat),
      this.assmatService.getAvailability(this.idAssmat)
    ]).subscribe(([assmatForm, dataAvailability]: [AssmatForm, AssmatAvailabilityAgrements]) => {
      this.assmatData = assmatForm.data;

      this.modeAccueil = this.assmatData.Bloc_mode_accueil;
      this.complement = this.assmatData.Bloc_complement;
      this.valeursLibres = this.assmatData.Bloc_libre;
      this.availability = dataAvailability.disponibilites;
      this.assmatFormSteps = this.getFormattedSteps(assmatForm.config);
      this.listValeursLibres = this.getFormattedValeursLibres(assmatForm.config);
      this.loading = false;
    })
  }

  getFormattedSteps(steps: StepType[], excludedNames?: string[]) {
    return steps
      .filter(step => !excludedNames || !excludedNames.includes(step.stepName))
      .map(step => ({ stepName: step.stepName, label: step.label }));
  }

  getFormattedValeursLibres(form: StepType[]) {
    let valeursLibresFieldsConfig: ExtendedFormlyFieldConfig[] = [];
    let formattedValeursLibres: any[] = [];
    let valeursLibresStep = form.find(formStep => formStep.stepName === "valeursLibres");

    if (valeursLibresStep) {
      let listKeys = this.formConfigService.getKeysListOfOneStep(valeursLibresStep);

      listKeys.forEach(key => {
        valeursLibresFieldsConfig.push(this.formConfigService.findFieldByName(valeursLibresStep.fields, key));
      });

      let mapValeursLibresFieldsConfig = valeursLibresFieldsConfig.map(val => {
        return { key: val.key, label: val.templateOptions.label, type: val.type, options: val.templateOptions.options };
      }).filter(val => val.key !== "message");

      mapValeursLibresFieldsConfig.forEach(val => {
        for (const key in this.valeursLibres) {

          if (Object.prototype.hasOwnProperty.call(this.valeursLibres, key)) {
            let value = this.valeursLibres[key];

            if (key === val.key) {

              if (val.options && value) {
                const options = val.options as any[];
                value = options.find(opt => opt.value === value)?.label;
              }

              if (val.type === 'datepicker') {
                value = moment(value, "YYYYMMDD").toDate();
              }

              if (value) {
                formattedValeursLibres.push({ label: val.label, type: val.type, value });
              }

            }
          }
        }
      });
      return formattedValeursLibres;
    }
  }

  openForm(stepName) {
    const path = `/account/assmat/${stepName}`;
    this.router.navigate([path]);
  }

}
