import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usager } from '@app/models/consumer';
import { anneeScolaire, LasidoInscription, LasidoInscriptionConfig } from '@app/models/lasido';
import { FamilyService, PermissionService, PlatformService } from '@app/services';
import { LasidoService } from '@app/services/lasido.service';
import { forkJoin, interval, Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface UsagerForFilter {
  id: number,
  nomReferent: string
}
@Component({
  selector: 'app-lasido-inscription',
  templateUrl: './lasido-inscription.component.html',
  styleUrls: ['./lasido-inscription.component.scss']
})
export class LasidoInscriptionComponent implements OnInit, OnDestroy {

  inscriptions: LasidoInscription[];
  inscriptionsFiltered: LasidoInscription[];
  loading = false;
  drawerOpened = false;
  config: LasidoInscriptionConfig;
  listUsagers: UsagerForFilter[];
  filterUsagers: UsagerForFilter[] = [];
  anneesScolaires: anneeScolaire[];
  permToCreatePreInscription: boolean;
  checkPermInscription: Subscription;
  renouvellementInscriptionsEnabled: boolean;
  filter: { anneesScolaires: number[], usagers: Usager[] } = { anneesScolaires: [], usagers: [] };
  onDestroy$ = new Subject();

  constructor(
    public platformService: PlatformService,
    private permService: PermissionService,
    private familyService: FamilyService,
    private lasidoService: LasidoService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.permToCreatePreInscription = this.permService.hasPermission('lasido_create') && this.familyService.currentFamily.active;
    this.loadData().subscribe(_ => {
      this.anneesScolaires = this.config.listAnneesScolairesToShowDossier;
      this.listUsagers = this.setListUsager(this.inscriptions);
      this.inscriptions = this.inscriptions.filter((insc: LasidoInscription) => {
        return this.config.listAnneesScolairesToShowDossier.some(annee => annee.idAnneeScolaire === insc.anneeScolaire);
      }).reverse();

      this.inscriptions.forEach(insc => {
        insc.depDiscNivGroup.forEach(discNiv => {
          if (discNiv.cours?.length && discNiv.renewal === "oui") {
            discNiv.cours = discNiv.cours.filter(cours => cours.choice).sort((a, b) => a.ordre - b.ordre);
          }

          if (this.statutExist('A completer', insc)) {
            insc.isCompleted = true;
          }

          if (this.checkAllNonReservable(insc)) {
            insc.allNonReservable = true;
          }
        })
      })
      this.inscriptionsFiltered = [...this.inscriptions];
    });


    this.checkPermInscription = interval(1000).subscribe(_ => {
      if (this.config) {
        this.renouvellementInscriptionsEnabled = this.lasidoService.checkPermRenouvellementInscription(this.config);
      }
    });
  }

  loadData() {
    return this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      switchMap(family => {

        const loaders = [];

        loaders.push(this.lasidoService.getConfigInscriptionForUser().pipe(tap(c => this.config = c)));
        loaders.push(this.familyService.getFamilyInscriptions(family, 'Lasido').pipe(tap((inscriptions: LasidoInscription[]) => this.inscriptions = inscriptions)));

        return forkJoin(loaders);
      })
    );
  }

  setListUsager(inscriptions: LasidoInscription[]): UsagerForFilter[] {
    let listUsagersInscriptions = inscriptions.map(insc => {
      let id = insc.idAdulte !== 0 ? insc.idAdulte : insc.idEnfant;
      return { id, nomReferent: insc.nomReferent }
    })

    return listUsagersInscriptions.filter((value, index, self) =>
      index === self.findIndex((child) => (
        child.id === value.id && child.nomReferent === value.nomReferent
      ))
    )
  }

  statutExist(etat: string, inscription: LasidoInscription): boolean {
    let inscriptionFiltered = inscription.depDiscNivGroup?.filter(discNiv => discNiv.disciplineReservable);
    return !!inscriptionFiltered.find(discNiv => discNiv.etatRenouvellement === etat);
  }

  checkAllNonReservable(inscription: LasidoInscription): boolean {
    return inscription.depDiscNivGroup.every(discNiv => !discNiv.disciplineReservable);
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleInscriptionsOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  onClickRenouvellement(item: LasidoInscription) {
    this.router.navigate([`account/lasido-inscriptions/${item.idInscriptionLasido}`]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.checkPermInscription.unsubscribe();
  }

  changeSelectAnneeScolaire(data: number[]) {
    this.applyFilter();
  }

  changeSelectUsager(data: Usager[]) {
    this.applyFilter();
  }

  applyFilter() {
    if (this.filter.anneesScolaires.length || this.filterUsagers.length) {
      this.inscriptionsFiltered = this.inscriptions.filter((insc: LasidoInscription) => {

        let resultAnneesScolaires;
        let resultUsagers;

        if (this.filter.anneesScolaires.length) {
          resultAnneesScolaires = this.filter.anneesScolaires.includes(insc.anneeScolaire);
        } else {
          resultAnneesScolaires = true;
        }

        if (this.filterUsagers.length) {
          resultUsagers = this.filterUsagers.some((usager: UsagerForFilter) => {
            return usager.nomReferent === insc.nomReferent && usager.id === insc.idAdulte || usager.id === insc.idEnfant;
          })
        } else {
          resultUsagers = true;
        }

        return resultAnneesScolaires && resultUsagers;

      })
    } else {
      this.inscriptionsFiltered = this.inscriptions;
    }
  }
}
