<div class="board-container" [ngClass]="{compact: compact}">
  <mat-card *ngFor="let card of boardCards" [ngClass]="getCardClasses(card)">
    <mat-card-header [portailCustomStyle]="card.titleStyle" [include]="['borderColor', 'backgroundColor']"
      [hidden]="!card.title">
      <mat-card-title [portailCustomStyle]="card.titleStyle" [include]="headerStyleParts">
        <span class="fx-direction-row fx-between-center fx-gap-10">
          <i *ngIf="card.iconDomino" class="icodomino-{{ card.iconDomino }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <i *ngIf="card.iconFontawesome && card.typeIcon" class="{{card.typeIcon}} fa-{{ card.iconFontawesome }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <i *ngIf="card.iconFontawesome && !card.typeIcon" class="fas fa-{{ card.iconFontawesome }}"
            [ngStyle]="{'font-size.px': card.sizeIcon, 'color': card.titleStyle?.color}"></i>
          <span style="text-align: center;" [portailCustomStyle]="card.titleStyle"
            [exclude]="headerStyleParts">{{card.title}}</span>
        </span>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content *ngIf="card.type === 'content'" class="content" [innerHTML]="card.content|safe"
      [portailCustomStyle]="card.contentStyle" [include]="['padding']">
    </mat-card-content>

    <mat-card-content *ngIf="card.type === 'component'" class="component">
      <app-login *ngIf="card.component === 'login'" [homeCard]="true"></app-login>
      <app-home-news *ngIf="card.component === 'news' || card.component === 'newsList'"
        [categories]="card.newsCategories || []" [nbNewsEnabled]="card.nbNewsEnabled" [newsStyleType]="card.component" [isAdmin]="isAdmin">
      </app-home-news>
      <p *ngIf="card.component === 'links'">
        <app-menu-custom-links></app-menu-custom-links>
      </p>
    </mat-card-content>
  </mat-card>
</div>
