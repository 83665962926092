<div class="top-buttons">
  <button *ngIf="regie.activePaiement && !regie.pausePaiement" [disabled]="!hasFactureToPay" mat-raised-button
    color="primary" routerLink='/account/paiement/{{ regie.id }}'>
    <mat-icon style="margin-right: 5px;">payment</mat-icon>
    <span *ngIf="regie.payMode !== 'prepay'">{{ 'facture.by_regie.pay_online'|trans }}</span>
    <span *ngIf="regie.payMode === 'prepay'">{{ 'facture.by_regie.reload_online'|trans }}</span>
  </button>
</div>
<mat-card *ngIf="regie.pausePaiement && regie.pausePaiementMessage" class="primary-lighter-bg"
  style="margin-bottom: 10px;">
  <div class="fx-direction-row fx-start-center">
    <mat-icon color="accent" style="margin-right: 24px;">info</mat-icon>
    <div [innerHTML]="regie.pausePaiementMessage | safe"></div>
  </div>
</mat-card>

<div *ngIf="!regie.afficheReglements" class="container-tab-with-reglement">
  <app-user-factures-list [factures]="factures" [config]="regie"></app-user-factures-list>
</div>

<div *ngIf="regie.afficheReglements" class="container-mat-tab-group">
  <mat-tab-group backgroundColor="primary" color="accent">
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <i class="icon icodomino-modele-facturation" style="margin-right: 2px;"></i>
        {{ 'facture.by_regie.factures'|trans }}
      </ng-template>
      <app-user-factures-list [factures]="factures" [config]="regie"></app-user-factures-list>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        <i class="icon icodomino-mode-reglement" style="margin-right: 2px;"></i>
        {{ 'facture.by_regie.reglements'|trans }}
      </ng-template>
      <app-reglements-list [reglements]="visibleReglements" paginatorSize="10" [afficheTotaux]="regie.afficheTotaux">
      </app-reglements-list>
    </mat-tab>
  </mat-tab-group>
</div>
