<div class="conteneur" *ngIf="!isLoading && familleActive; else loader">
  <mat-card
    [ngClass]="{'form-container': !platformService.isMobile, 'form-container-mobile': platformService.isMobile}">
    <mat-card-header class="card-header fx-direction-row fx-center-center">

      <mat-card-title class="fx-direction-row fx-center-center">
        <span *ngIf="!isEdit">{{ 'mandat_sepa.new_mandat_sepa'|trans }}</span>
        <span *ngIf="isEdit">{{ 'mandat_sepa.edit_mandat_sepa'|trans }}</span>
      </mat-card-title>
    </mat-card-header>

    <form [formGroup]="sepaMandatForm" (ngSubmit)="onSubmit()" class="form">

      <mat-form-field class="no-hint">
        <mat-label>Créancier</mat-label>
        <mat-select formControlName="idSEPACreancier" (change)="handleSelectCreancierChange($event)">
          <mat-option *ngFor="let creancier of config.tabCreanciers" [value]="creancier.idSEPACreancier">
            {{ creancier.label ? creancier.label : creancier.creancier}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="sepaMandatForm.get('creancier').invalid && sepaMandatForm.get('creancier').touched">
          <div *ngIf="sepaMandatForm.get('creancier').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

      <mat-form-field class="no-hint">
        <mat-label>Nom / Prenom</mat-label>
        <input matInput type="text" formControlName="nomPrenom">
        <!-- <mat-error *ngIf="sepaMandatForm.get('nomPrenom').invalid && sepaMandatForm.get('nomPrenom').touched">
          <div *ngIf="sepaMandatForm.get('nomPrenom').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

      <mat-form-field class="no-hint">
        <mat-label>Adresse</mat-label>
        <input matInput type="text" formControlName="adresse">
        <!-- <mat-error *ngIf="sepaMandatForm.get('adresse').invalid && sepaMandatForm.get('adresse').touched">
            <div *ngIf="sepaMandatForm.get('adresse').errors.required">Le champ est obligatoire.</div>
          </mat-error> -->
      </mat-form-field>

      <div class="fx-direction-row fx-wrap">
        <mat-form-field class="no-hint field-cp">
          <mat-label>Code postal</mat-label>
          <input matInput type="text" formControlName="codePostal">
          <!-- <mat-error *ngIf="sepaMandatForm.get('codePostal').invalid && sepaMandatForm.get('codePostal').touched">
              <div *ngIf="sepaMandatForm.get('codePostal').errors.required">Le champ est obligatoire.</div>
            </mat-error> -->
        </mat-form-field>

        <mat-form-field class="no-hint field-ville">
          <mat-label>Ville</mat-label>
          <input matInput type="text" formControlName="ville">
          <!-- <mat-error *ngIf="sepaMandatForm.get('ville').invalid && sepaMandatForm.get('ville').touched">
              <div *ngIf="sepaMandatForm.get('ville').errors.required">Le champ est obligatoire.</div>
            </mat-error> -->
        </mat-form-field>
      </div>

      <div class="fx-direction-row fx-wrap">
        <mat-form-field class="no-hint field-iban">
          <mat-label>IBAN</mat-label>
          <input matInput type="text" formControlName="iban" (input)="formatIban($event)">
          <mat-error *ngIf="sepaMandatForm.get('iban').invalid && sepaMandatForm.get('iban').touched">
            <!-- <div *ngIf="sepaMandatForm.get('iban').errors.required">Le champ IBAN est obligatoire.</div> -->
            <div *ngIf="sepaMandatForm.get('iban').errors.invalidPays">Le pays correspondant au numéro IBAN n'est pas
              valide.</div>
            <div *ngIf="sepaMandatForm.get('iban').errors.invalidIbanFormat">Format de l'IBAN invalide.</div>
            <div *ngIf="sepaMandatForm.get('iban').errors.invalidIban">IBAN Invalide.</div>
          </mat-error>
        </mat-form-field>


        <mat-form-field class="no-hint field-bic">
          <mat-label>BIC</mat-label>
          <input matInput type="text" formControlName="bic" (focus)="searchBic()" (input)="formatBic($event)">
          <mat-spinner matSuffix *ngIf="isLoadingSearchBic" [diameter]="22" style="float: right; margin-left: 8px">
          </mat-spinner>
          <mat-error *ngIf="sepaMandatForm.get('bic').invalid && sepaMandatForm.get('bic').touched">
            <!-- <div *ngIf="sepaMandatForm.get('bic').errors.required">Le champ BIC est obligatoire.</div> -->
            <div *ngIf="sepaMandatForm.get('bic').errors.invalidBicFormat">Format du BIC invalide.</div>
          </mat-error>
        </mat-form-field>

      </div>


      <mat-form-field class="no-hint">
        <mat-label>Banque</mat-label>
        <input matInput type="text" formControlName="banqueMandat">
        <!-- <mat-error *ngIf="sepaMandatForm.get('banque').invalid && sepaMandatForm.get('banque').touched">
          <div *ngIf="sepaMandatForm.get('banque').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

      <mat-form-field class="no-hint">
        <mat-label>Fait à</mat-label>
        <input matInput type="text" formControlName="lieuSignature">
        <!-- <mat-error *ngIf="sepaMandatForm.get('signatureLieu').invalid && sepaMandatForm.get('signatureLieu').touched">
          <div *ngIf="sepaMandatForm.get('signatureLieu').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

      <mat-card-actions align="center">
        <button mat-raised-button color="primary" type="submit" [disabled]="sepaMandatForm.invalid || saving">
          Valider
          <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
            class="icon-loader"></mat-progress-spinner>
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>
