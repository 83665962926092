<div class="container" *ngIf="data; else loader">

  <div *ngIf="paymentReturnData" class="payment-return">
    <mat-card *ngIf="paymentReturnData.done" class="flash-message success-lighter-bg" [class.dismiss]="dismiss">
      <mat-icon>check_circle_outline</mat-icon>
      <span class="content">
        {{ 'facture.paiement.retour.success'|trans:{
          datetime: paymentReturnData.datetime|date:'long',
          ref: paymentReturnData.ref
        } }}
      </span>
      <span class="close-flash" (click)="dismiss = true">&times;</span>
    </mat-card>

    <mat-card *ngIf="!paymentReturnData.done" class="flash-message warn-lighter-bg" [class.dismiss]="dismiss">
      <mat-icon>error_outline</mat-icon>
      <span class="content">{{ 'facture.paiement.retour.error'|trans:paymentReturnData }}</span>
      <span class="close-flash" (click)="dismiss = true">&times;</span>
    </mat-card>
  </div>

  <mat-accordion *ngIf="data.length; else emptyMessage">
    <ng-container *ngFor="let row of data">
      <mat-expansion-panel *ngIf="row.regie.afficheFactures && row.factures.length"
        [expanded]="data.length === 1 || row.regie.id === expanded">

        <mat-expansion-panel-header collapsedHeight="50px" expandedHeight="50px">
          <mat-panel-title>
            <span class="regie-title">
              {{ row.regie.id!==0 ? (row.regie.titre || row.regie.designation) : '&nbsp;' |titlecase }}
            </span>

            <div *ngIf="facturesToPayByRegie[row.regie.id] > 0" class="alert-facture-to-pay">
              <mat-icon [matBadge]="facturesToPayByRegie[row.regie.id]"
                [matTooltip]="facturesToPayByRegie[row.regie.id] > 1 ? facturesToPayByRegie[row.regie.id] + ' factures à payer' : '1 facture à payer'"
                matBadgeColor="warn">payment</mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-regie-factures [factures]="row.factures" [reglements]="row.reglements" [regie]="row.regie">
        </app-regie-factures>

      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<ng-template #emptyMessage>
  <h3 style="text-align: center;">{{ 'facture.list.empty'|trans }}</h3>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'facture.list.loading'|trans" class="margin"></app-loader>
</ng-template>

<ng-template #regie>

</ng-template>
