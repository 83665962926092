<ng-container *ngIf="news; else loader">
  <div *ngIf="newsStyleType === 'news'" class="carousel-news">
    <swiper [config]="swiperConfig">
      <ng-template *ngFor="let actu of news" swiperSlide>
        <a [routerLink]="'/news/' + actu.id" class="no-underline-animation">
          <h3>{{ actu.title }}</h3>
          <div *ngIf="actu.img">
            <img class="img-thumbnail" [src]="actu.img">
          </div>
          <p *ngIf="actu.subTitle" class="news-subtitle">{{ actu.subTitle }}</p>
        </a>
      </ng-template>

      <ng-template *ngIf="seeMoreLink" swiperSlide>
        <button mat-raised-button color="primary" [routerLink]="seeMoreLink" class="btn-more" style="margin-top: 40%;">
          Voir plus
          <mat-icon>chevron_right</mat-icon>
        </button>
      </ng-template>
    </swiper>
  </div>

  <div *ngIf="newsStyleType === 'newsList'" class="list-container">
    <div *ngFor="let actu of news">
      <a [routerLink]="'/news/' + actu.id" class="no-underline-animation">
        <div class="sub-container">
          <img *ngIf="actu.img" class="img-thumbnail" [src]="actu.img" alt="">

          <div class="conteneur-title-subTitle">
            <h3 class="news-title">{{ actu.title }}</h3>
            <p class="news-subTitle">{{ actu.subTitle }}</p>
          </div>
        </div>
      </a>
    </div>


    <button *ngIf="seeMoreLink" mat-stroked-button color="primary" [routerLink]="seeMoreLink" class="btn-more">
      Voir plus
      <mat-icon>chevron_right</mat-icon>
    </button>

  </div>
</ng-container>

<ng-template #loader>
  <app-loader style="margin: 50px auto">Chargement des actualités</app-loader>
</ng-template>
