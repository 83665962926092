<div class="conteneur" *ngIf="!loading; else loader">
  <div class="fx-direction-row fx-wrap">

    <mat-card class="card fx-flex-grow" *ngIf="permToEditDisponibilites">
      <mat-card-header class="card-header fx-direction-row fx-between-center">
        <mat-card-title class="fx-direction-row fx-start-center">
          <app-icon style="margin-right: 5px;" type="do" icon="jour-ferie">
          </app-icon>
          <span>Disponibilités</span>
        </mat-card-title>
        <button style="margin-bottom: 20px;" mat-mini-fab color="primary"
          [matTooltip]="permToEditDisponibilites ? 'Modifier' : 'Voir'" routerLink="/account/assmat/availability">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content style="flex: 1; margin-left: 20px;">
        <div>
          <ul>
            <li *ngFor="let item of availability">
              <p><b>Agrément secondaire :
                </b>
                {{item.agrementSecondaire.designation ? item.agrementSecondaire.designation : "Aucun"}}
                , <b>Capacité : </b>
                {{item.capacite}} , <b>Dates :
                </b>{{item.dateDebut && !item.dateFin ? 'À partir du' : !item.dateDebut && item.dateFin ? "Jusqu'au" :
                !item.dateDebut && !item.dateFin ? "" : 'Du'}}
                {{item.dateDebut | date:"shortDate"}} {{item.dateFin && item.dateDebut ? 'au' : ''}}
                {{item.dateFin | date:"shortDate"}}</p>
            </li>
          </ul>
        </div>
      </mat-card-content>
    </mat-card>

    <ng-container *ngFor="let step of assmatFormSteps">
      <mat-card class="card"
        [ngClass]="{'multiple-card': assmatFormSteps.length > 1, 'one-card': assmatFormSteps.length <= 1 }">
        <mat-card-header class="card-header fx-direction-row fx-between-center">
          <mat-card-title class="fx-direction-row fx-start-center">
            <app-icon *ngIf="step.stepName === 'souhaitsAccueil'" style="margin-right: 5px;" type="do" icon="accueil">
            </app-icon>
            <mat-icon *ngIf="step.stepName !== 'souhaitsAccueil'" style="margin-right: 5px;">description</mat-icon>
            <span>{{ step.label }}</span>
          </mat-card-title>
          <button style="margin-bottom: 20px;" mat-mini-fab color="primary"
            [matTooltip]="permToEditSouhaitsAccueil ? 'Modifier' : 'Voir'"
            routerLink="/account/assmat/{{step.stepName}}">
            <mat-icon *ngIf="permToEditSouhaitsAccueil">edit</mat-icon>
            <mat-icon *ngIf="!permToEditSouhaitsAccueil">visibility</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content style="flex: 1; margin-left: 20px;">
          <ng-container *ngIf="step.stepName === 'souhaitsAccueil'" [ngTemplateOutlet]="souhaitsAccueil"></ng-container>
          <ng-container *ngIf="step.stepName === 'valeursLibres'" [ngTemplateOutlet]="valeursLibres"></ng-container>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </div>
</div>

<ng-template #valeursLibres>
  <div *ngFor="let valeurLibre of listValeursLibres; let i = index">
    <div [ngSwitch]="valeurLibre.type">
      <p *ngSwitchCase="'datepicker'">
        <b>{{valeurLibre.label}} : </b> {{valeurLibre.value | date:"shortDate"}}
      </p>
      <p *ngSwitchDefault>
        <b>{{valeurLibre.label}} : </b> {{valeurLibre.value}}
      </p>
    </div>

  </div>
</ng-template>


<ng-template #souhaitsAccueil>
  <div *ngIf="complement">
    <p *ngIf="complement.nbEnfantSouhait"><b>Nombre d'enfant souhaité : </b> {{complement.nbEnfantSouhait}}</p>
    <p *ngIf="complement.ageDebutEnfantSouhait && complement.ageFinEnfantSouhait"><b>Tranche d'âge souhaitée : </b>
      Entre {{complement.ageDebutEnfantSouhait}} et
      {{complement.ageFinEnfantSouhait}} mois</p>
  </div>
  <div *ngIf="modeAccueil"><b>Mode(s) d'accueil(s) souhaité(s) : </b>
    <ul>
      <li *ngIf="modeAccueil.accueilTempsComplet === 'Oui'">
        Temps Complet
      </li>
      <li *ngIf="modeAccueil.accueilTempsPartiel === 'Oui'">
        Temps Partiel
      </li>
      <li *ngIf="modeAccueil.accueilPeriscolaire === 'Oui'">
        Accueil periscolaire
      </li>
      <li *ngIf="modeAccueil.accueilAtypique === 'Oui'">
        Accueil avec horaires atypiques
      </li>
      <li *ngIf="modeAccueil.accueilUrgence === 'Oui'">
        Accueil en urgence
      </li>
      <li *ngIf="modeAccueil.accueilHandicap === 'Oui'">
        Accueil d'un enfant en situation de handicap
      </li>
      <li *ngIf="modeAccueil.accueilNuit === 'Oui'">
        Accueil nuit
      </li>
      <li *ngIf="modeAccueil.accueilWeekend === 'Oui'">
        Accueil weekend
      </li>
      <li *ngIf="modeAccueil.accueilDepannage === 'Oui'">
        Accueil dépannage
      </li>
      <li *ngIf="modeAccueil.accueilMercrediVacScolaires === 'Oui'">
        Accueil mercredis / vacances scolaires
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader message="Chargement des données..." class="margin"></app-loader>
</ng-template>