<div class="conteneur" *ngIf="preinscriptions; else loader">
  <div class="fx-direction-col">

    <mat-card *ngFor="let preinsc of preinscriptions" class="card">
      <mat-card-header class="card-header fx-direction-row fx-between-center">
        <mat-card-title class="fx-direction-row fx-start-center">
          <mat-icon style="margin-right: 5px;">person</mat-icon>
          <span>{{ preinsc.nom }} {{ preinsc.prenom }}</span>
        </mat-card-title>
        <mat-card-subtitle class="fx-direction-row fx-start-center" style="margin-left: 5px;">
          <span *ngIf="preinsc.dateDeNaissance !== '0000-00-00'">Né le :
            {{preinsc.dateDeNaissance | date:'shortDate'}}</span>
          <span *ngIf="preinsc.dateAccouchement !== '0000-00-00'">Naissance prévue le :
            {{preinsc.dateAccouchement | date:'shortDate'}}</span>
        </mat-card-subtitle>
        <button style="margin-bottom: 20px;" mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-card-header>
      <mat-menu #menu="matMenu" xPosition="before">
        <button *ngFor="let formPart of formParts" mat-menu-item
          (click)="openForm(preinsc.idPreInscription, formPart.stepName)">
          <mat-icon color="primary">
            {{ readOnly || (preinsc.decision !== 'En attente' && preinsc.decision !== '') ? 'visibility' : 'edit' }}
          </mat-icon>
          {{ formPart.label }}
        </button>
        <!-- <button mat-menu-item color="primary" routerLink="" *ngIf="permService.hasPermission('preinscription_edit')">
          <mat-icon color="warn">clear</mat-icon>
          Supprimer
        </button> -->
      </mat-menu>
      <mat-card-content style="margin-left: 20px;">
        <p><b>Date de la demande : </b>{{ preinsc.dateDemande|date:'shortDate' }}</p>
        <p class="fx-direction-row fx-wrap">
          <b>Souhait(s) : </b>
          <span>
            <ol class="list-souhaits">
              <li *ngFor="let souhait of preinsc._Souhaits">
                {{souhait._Etablissement.designation}} ({{souhait._Accueil.designation}})</li>
            </ol>
          </span>
        </p>

        <p>
          <b>Période d'inscription : </b>
          <ng-container *ngIf="preinsc.souhaitDateDebut && preinsc.souhaitDateDebut != '0000-00-00'">
            <span *ngIf="preinsc.souhaitDateFin && preinsc.souhaitDateFin != '0000-00-00'; else fromDate">
              du {{ preinsc.souhaitDateDebut|date:'shortDate' }} au {{ preinsc.souhaitDateFin|date:'shortDate' }}
            </span>
            <ng-template #fromDate>
              <span>à partir du {{ preinsc.souhaitDateDebut|date:'shortDate' }}</span>
            </ng-template>
          </ng-container>
        </p>

        <p class="fx-direction-row fx-wrap fx-start-center fx-gap-2">
          <b>Statut : </b>
          <app-icon *ngIf="preinsc.decision ==='Refusée'" type="fa" icon="times-circle" class="icon-decision warn">
          </app-icon>
          <app-icon *ngIf="preinsc.decision ==='Acceptée'" type="fa" icon="check-circle" class="icon-decision primary">
          </app-icon>
          <app-icon *ngIf="preinsc.decision ==='Abandon'" type="fa" icon="trash" class="icon-decision"></app-icon>
          <app-icon *ngIf="preinsc.decision ==='En attente'" type="fa" icon="question-circle"
            class="icon-decision accent"></app-icon>
          <app-icon *ngIf="preinsc.decision ===''" type="fa" icon="question-circle" class="icon-decision accent">
          </app-icon>
          <span>{{ preinsc.decision || 'En attente'}}</span>
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="permToCreate" class="card card-add">
      <mat-card-header class="card-header fx-direction-row fx-center-center">
        <mat-card-title class="fx-direction-row fx-center-center">
          Nouvelle pré-inscription
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top: 20px;" class="fx-direction-row fx-center-center">
        <button mat-fab color="primary" matTooltip="Nouvelle pré-inscription" routerLink="/account/preinscription/add">
          <mat-icon>add</mat-icon>
        </button>
      </mat-card-content>
    </mat-card>

  </div>

  <div *ngIf="!preinscriptions.length && readOnly">
    <h2 style="text-align: center;">Vous n'avez pas de pré-inscription(s).</h2>
  </div>
</div>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>