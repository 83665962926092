import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Usager } from '@app/models/consumer';
import { DemarcheItem, DemarcheStep } from '@app/models/demarche';
import { ChildService, FamilyService, PermissionService } from '@app/services';

@Component({
  selector: 'app-demarche-select-step',
  templateUrl: './demarche-select-step.component.html',
  styleUrls: ['./demarche-select-step.component.scss']
})
export class DemarcheSelectStepComponent implements OnInit {

  @Input() demarche: DemarcheItem;
  @Input() stepper: MatStepper;
  @Input() step: DemarcheStep;
  @Output() onValidate = new EventEmitter<Usager>();
  listUsagers: Usager[];
  selectedUsager: Usager;
  conjointExist: boolean;
  permToAddChild: boolean;
  permToEditFoyer: boolean;

  constructor(
    private router: Router,
    private familyService: FamilyService,
    private childService: ChildService,
    private permService: PermissionService
  ) { }

  ngOnInit(): void {

    if (this.familyService.currentFamily.active) {
      this.permToAddChild = this.permService.hasPermission('child_create');
      this.permToEditFoyer = this.permService.hasPermission('foyer_edit');
    }

    this.familyService.getListUsagers(this.familyService.currentFamily.id).subscribe(usagers => {

      this.listUsagers = usagers;

      if (this.childService.lastIdChildCreate.value) {
        this.selectedUsager = this.listUsagers.find(usager => usager.id === this.childService.lastIdChildCreate.value);
        this.childService.lastIdChildCreate.next(0);
      }

      if (this.familyService.idConjointCreate.value) {
        this.selectedUsager = this.listUsagers.find(usager => usager.id === this.familyService.idConjointCreate.value);
        this.familyService.idConjointCreate.next(0);
      }

      this.conjointExist = this.listUsagers.find(usager => usager.estConjoint) ? true : false;
    })
  }

  openForm(entityFormPath: string) {
    const path = entityFormPath === 'children' ? `/account/${entityFormPath}/add` : `/account/${entityFormPath}/edit`;
    let demarcheString = JSON.stringify(this.demarche);
    const state = { demarcheString, stepIndex: this.stepper.selectedIndex }
    history.replaceState({ ...history.state, ...state }, '')
    this.router.navigate([path]);
  }

  onValider() {
    // this.step.completed = true;
    // this.step.editable = false;
    setTimeout(() => {
      this.onValidate.emit(this.selectedUsager);
      this.stepper.next();
    });
  }

}
