<mat-accordion *ngIf="data; else loader" class="main-accordion">
  <mat-expansion-panel *ngFor="let regie of data; let first = first" [expanded]="first">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="regie-title">
          <i class="icon icodomino-regie"></i>
          {{ regie.nomRegie }}
        </h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="regie-container">
      <div *ngFor="let inscription of regie.inscriptions">
        <h4 class="inscription-title" *ngIf="configTicket.afficheUsagers || !configTicket.groupInscriptions">
          <mat-icon color="primary">account_circle</mat-icon>
          <ng-container *ngIf="configTicket.groupInscriptions">
            <span *ngFor="let usager of inscription.nomUsager; let first = first">
              <span *ngIf="!first">,</span> {{ usager }}
            </span>
          </ng-container>
          <ng-container *ngIf="!configTicket.groupInscriptions">
            {{inscription.nomUsager}}
          </ng-container>
        </h4>

        <ng-container *ngFor="let rub of inscription.rubriquesFacturation; let last = last">
          <div class="rubrique-row">
            <div class="info">
              <app-icon type="fa" icon="ticket-alt" style="margin-right: 10px;" class="accent"></app-icon>
              <span>{{ rub.designation }}</span>
            </div>

            <div class="quantity-input">

              <button mat-icon-button [class.hidden]="!rub.quantite" (click)="decrease(rub)">
                <mat-icon class="warn">remove_circle</mat-icon>
              </button>

              <mat-form-field class="no-hint" style="max-width: 70px; font-size: small;">
                <mat-label>Quantité</mat-label>
                <input matInput [(ngModel)]="rub.quantite" (ngModelChange)="calculateTotal()" type="number" min="0"
                  autocomplete="off" style="text-align: center;">
              </mat-form-field>

              <button mat-icon-button (click)="increase(rub)">
                <mat-icon class="success-color">add_circle</mat-icon>
              </button>

              <span class="multiply">x</span>
              <span class="price">{{ rub.tarif | currency:'€' }}</span>
            </div>
          </div>
          <mat-divider></mat-divider>
        </ng-container>
      </div>

      <mat-card *ngIf="regie.pausePaiement && regie.pausePaiementMessage" class="primary-lighter-bg"
        style="margin-top: 10px;">
        <div class="fx-direction-row fx-start-center">
          <mat-icon color="accent" style="margin-right: 24px;">info</mat-icon>
          <div [innerHTML]="regie.pausePaiementMessage |safe "></div>
        </div>
      </mat-card>

      <div *ngIf="!regie.pausePaiement" class="regie-total">
        <h4>Total : {{ regie.total|currency:'€'}}</h4>
        <button mat-raised-button color="accent" [disabled]="(regie.total || 0) <= 0 || !loadingRegie"
          (click)="buyTickets(regie)">
          <mat-icon>payment</mat-icon>
          Acheter
        </button>
      </div>

    </div>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #loader>
  <app-loader class="margin">Chargement des données ...</app-loader>
</ng-template>
