
export interface AuthentificationConfig {
  externalAuth?: OpenIdConfig;
  doubleAuth?: DoubleAuthConfig;
  passwordRules?: passwordRulesConfig;
}

///////// Open Id Stuff /////////
type OpenIdButtonType = 'image-only' | 'logo+label' | 'label-only';
type OpenIdAlgo = 'HS256' | 'RS256';
type OpenIdEnv = 'integration' | 'production';

export const OIDC_LOGIN_CALLBACK_PATH: Array<string> = ['oidc-authorize', 'oidc-login-callback', 'login-callback', 'test-callback-login']
export const OIDC_LOGOUT_CALLBACK_PATH: Array<string> = ['oidc-logout', 'oidc-logout-callback', 'logout-callback', 'test-callback-logout']

export interface OpenIdUrlEnv {
  urlAuthorization: string;
  urlToken: string;
  urlUserInfo: string;
  urlLogout: string;
}
export interface OpenIdConfig {
  isActive: boolean;

  identityProvider: string;
  providerName: string;

  providerLogo: string;
  providerButtonType: OpenIdButtonType; // 'image-only' | 'logo+label' | 'label-only'
  providerButtonImage: string;
  providerButtonHoverImage: string;
  providerButtonLabel: string;

  environment: OpenIdEnv;
  integration: OpenIdUrlEnv;
  production: OpenIdUrlEnv;

  clientId: string;
  clientSecret: string;

  idTokenSignatureAlgo: OpenIdAlgo; // 'HS256' ou 'RS256'
  idTokenSignatureSecret: string; // pour HS256
  idTokenSignaturePublicKey: string; // pour RS256

  scopes: string;
  accountReconciliationMethod: string;

  msgHeader: string;
  linkInfoLabel: string;
  linkInfoUrl: string;

  msgNoUserFound: string;
}

export type oidcMessageType = 'infoUserNotFound' | 'error';
export type oidcVariablesForMessage = {
  fournisseurIdentite: string;
  civilitePrenomNom?: string;
}
export type oidcMessage = {
  type: oidcMessageType;
  message: string;
  variables?: oidcVariablesForMessage;
}


///////// Double Auth Stuff /////////
export interface DoubleAuthConfig {
  isActive: boolean;

  serviceName: string;

  delay: any;

  restrictGED: boolean;
  restrictSEPA: boolean;
  restrictFactures: boolean;

  msgDescriptionBeforeActivation: string;
  msgDescriptionAfterActivation: string;
  msgHelpActivation: string;
  msgHelpUtilisation: string;
  msgRestrictedArea: string;
}

export interface passwordRulesConfig {
  minPasswordLength: number;
  minPasswordStrength: number;
};
