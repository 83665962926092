<ng-container *ngIf="!inDialog">
  <ng-container *ngTemplateOutlet="contentDetails"></ng-container>
</ng-container>

<ng-container *ngIf="inDialog">
  <app-dialog-header [title]="content.titre"></app-dialog-header>
  <div mat-dialog-content class="thin-scrollbar container"
    [ngClass]="{'main-content-dialog': !platformService.isMobile}">
    <ng-container *ngTemplateOutlet="contentDetails"></ng-container>
  </div>
  <div mat-dialog-actions *ngIf="inDialog && content; else loader" style="justify-content: center; position: sticky;">
    <button [mat-dialog-close] mat-stroked-button color="primary"
      routerLink="/content-page/{{content.idContenuPerso }}">
      Lire le contenu
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</ng-container>

<ng-template #contentDetails>
  <div class="content thin-scrollbar" *ngIf="content; else loader">
    <h1 class="text-center content-title" *ngIf="content.showTitle">{{ content.titre }}
    </h1>
    <div class="contenu thin-scrollbar" style="flex: 1;">
      <p [innerHtml]="content.contenu | safe"></p>
    </div>
  </div>
  <ng-template #loader>
    <app-loader class="margin"></app-loader>
  </ng-template>
</ng-template>
