
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { clone } from '@app/utils/object';
import { Observable } from 'rxjs';
import { finalize, shareReplay, map } from 'rxjs/operators';


const MINUTE_IN_MS = 60 * 1000
const CACHE_TIMEOUT_DEFAULT = 30 // 30 minutes


@Injectable({
  providedIn: 'root'
})
export class CustomHttpService extends HttpClient {

  private cachedRessources = new Map<string, Observable<any>>();

  /**
   * Wrap the original `HttpClient.get()` method to add a cache feature 
   * @warning but **!! BE CAREFULL !!** : 
   *    - Don't use this for ressources that can be updated from this app and must be refreshed
   * 
   *
   * @param url           The endpoint URL.
   * @param cacheTimeOut  Delay of the cache in minutes (default 30 min)
   * @param options       The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HttpResponse`, with a response body in the requested type.
   */
  getWithCache<T>(url: string, cacheTimeout?: number, options?: {
    headers?: HttpHeaders | { [header: string]: string | string[]; };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>; };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {

    cacheTimeout = (cacheTimeout || CACHE_TIMEOUT_DEFAULT)
    const key = url + '#' + cacheTimeout

    if (this.cachedRessources.has(key)) {
      // console.log('getWithCache => this.cachedRessources /key', key)
      return this.cachedRessources.get(key) as Observable<T>

    } else {

      // console.log('getWithCache => new req /key', key)
      const timeoutInMs = cacheTimeout * MINUTE_IN_MS

      const newReq = this.get<T>(url, options).pipe(
        // Here we want to prevent multiple request at the same time
        // This worked, but seem it's based on a bug of RXJS and it will be deprecated in V7 (see https://github.com/ReactiveX/rxjs/issues/6260)
        // publishReplay(1, 5000),
        // refCount(),
        // take(1),

        // So here is my dirty solution : use shareReplay + destroy manually cached observable after timeOut
        // but caution because finalize() must not be shared, so it must be placed before the shareReplay()
        finalize(() => setTimeout(() => {
          this.cachedRessources.delete(key)
        }, timeoutInMs)),
        shareReplay({ bufferSize: 1, windowTime: timeoutInMs, refCount: false }),
        map(data => clone(data)) // to prevent all subscriber to share the same object
      )

      this.cachedRessources.set(key, newReq)

      return newReq
    }
  }



}
