import { Component, OnInit, TemplateRef, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GedConfig, GedDocument } from '@app/models/ged';
import { ConfigMandatSepa, MandatSepa, PiecesAFournir } from '@app/models/mandat-sepa';
import { FamilyService, PermissionService, PlatformService, SnackbarService } from '@app/services';
import { GedService } from '@app/services/ged.service';
import { MandatSepaService } from '@app/services/mandat-sepa.service';
import { Subject, forkJoin } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { FileUploadDialogComponent } from '@app/components/_user/ged/file-upload-dialog/file-upload-dialog.component';
import { DocDetailsComponent } from '../ged/doc-details/doc-details.component';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { MandatSepaPdfDialogComponent } from './mandat-sepa-pdf-dialog/mandat-sepa-pdf-dialog.component';

@Component({
  selector: 'app-mandats-sepa',
  templateUrl: './mandats-sepa.component.html',
  styleUrls: ['./mandats-sepa.component.scss']
})
export class MandatsSepaComponent implements OnInit {

  @ViewChild('disabledMandatDialog') disabledMandatDialog: TemplateRef<any>;
  @ViewChildren('inputFile') inputFile: QueryList<ElementRef>;

  mandatsSepa: MandatSepa[];
  mandatsSepaFiltered: MandatSepa[];
  documents: GedDocument[];
  isLoading: boolean;
  config: ConfigMandatSepa;
  configGed: GedConfig;
  permToCreate: boolean;
  permToEdit: boolean;
  drawerOpened: boolean = false;
  showEnabled: boolean = true;
  showDisabled: boolean = true;
  showWaiting: boolean = true;
  dialogRef: MatDialogRef<any>;
  isSaving: boolean = false;
  uploadingFile: File;
  uploadDialogRef: MatDialogRef<any>;
  previewURL: any;
  errorMessage: string;
  idFamille: number;
  documentDownload: { [doc: number]: number } = {};
  pdfMandatDownload: boolean;
  loadingPreview = false;
  idSelectedMandatToPrint: number;
  onDestroy$ = new Subject();

  constructor(
    private mandatService: MandatSepaService,
    private familyService: FamilyService,
    private permService: PermissionService,
    private router: Router,
    public platformService: PlatformService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private matDialog: MatDialog,
    private mandatSepaService: MandatSepaService,
    private gedService: GedService,
  ) { }

  ngOnInit(): void {

    this.permToEdit = this.permService.hasPermission('mandat_sepa_edit') && this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('mandat_sepa_create') && this.familyService.currentFamily.active;
    this.idFamille = this.familyService.currentFamily.id;

    this.loadData().subscribe(_ => {
      this.setDocsExist();
      this.mandatsSepaFiltered = [...this.mandatsSepa];
      this.isLoading = false;
    })
  }

  loadData() {
    this.isLoading = true;
    return this.familyService.currentFamilyReadyOnce$.pipe(
      switchMap(family => {
        const loaders = [];

        loaders.push(this.mandatSepaService.getConfigForUser()
          .pipe(tap((config: ConfigMandatSepa) => {
            this.config = config;
          })));

        loaders.push(this.gedService.getConfig().pipe(tap((configGed: GedConfig) => {
          this.configGed = configGed;
        })))

        loaders.push(this.mandatSepaService.getCurrentFamilyDocuments().pipe(tap((docs: GedDocument[]) => {
          this.documents = docs;
        })))

        loaders.push(this.mandatService.getFamilyMandatsSepa(family)
          .pipe(tap((mandatsSepa: MandatSepa[]) => {
            this.mandatsSepa = mandatsSepa.sort((a, b) => a.idSEPAMandat - b.idSEPAMandat).reverse();
            this.mandatsSepa.forEach(mandat => {
              mandat.pdf = "data:application/pdf;base64," + mandat.pdf;
              mandat.iban = mandat.iban.replace(/(.{4})/g, '$1 ');
            })
            this.setEtat();
          })))

        return forkJoin(loaders);
      })
    );
  }

  setEtat() {
    this.mandatsSepa.forEach(mandat => {
      if (mandat.estActif) {
        mandat.etat = "Actif";
      } else {
        mandat.etat = "Inactif";
      }

      if (mandat.idSEPAMandat < 0 || mandat.portailEnregistrementExist) {
        mandat.etat = "En attente";
      }
    })
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  onClickDisabled(mandat) {
    this.dialogRef = this.dialog.open(this.disabledMandatDialog, {
      data: { mandat }
    });
  }

  disabledMandat(mandat) {
    this.isSaving = true;
    mandat.estActif = false;
    mandat.iban = mandat.iban.replace(/\s/g, '');

    this.mandatService.saveMandatSepa(mandat, this.familyService.currentFamily.id).subscribe(_ => {
      this.loadData().subscribe(_ => {
        this.setDocsExist();
        this.mandatsSepaFiltered = [...this.mandatsSepa];
        this.isSaving = false;
        this.dialogRef.close();
        this.snackbar.info('info.success.saving');
        this.isLoading = false;
      });
    }, err => {
      this.snackbar.error("Erreur lors de l'enregistrement"); console.log(err);
      this.isSaving = false;
    })
  }

  toggleShowEnabled(event) {
    this.filterByEtat("Actif", event)
  }

  toggleShowDisabled(event) {
    this.filterByEtat("Inactif", event)
  }

  toggleShowWaiting(event) {
    this.filterByEtat("En attente", event)
  }

  filterByEtat(etat: string, event: boolean) {
    if (event === false) {
      this.mandatsSepaFiltered = this.mandatsSepaFiltered.filter(mandat => mandat.etat !== etat);
    } else {
      let mandats = this.mandatsSepa.filter(mandat => mandat.etat === etat);
      this.mandatsSepaFiltered.push(...mandats);
    }
  }

  openPreviewDialog(mandat: MandatSepa) {
    const dialog = this.matDialog.open(MandatSepaPdfDialogComponent, {
      data: { mandat, idFamille: this.idFamille }
    });

    this.platformService.adaptDialogToScreen(dialog);
  }

  setDocsExist() {
    this.mandatsSepa.forEach((mandat: MandatSepa) => {

      mandat.piecesAFournir?.forEach((piece: PiecesAFournir) => {
        piece.docExist = this.documents.some((doc: GedDocument) => doc.idDocumentElectronique > 0 && doc.idMandat === mandat.idSEPAMandat && doc.idPieceAFournir === piece.id);
      })

      mandat.allDocsExist = mandat.piecesAFournir?.length ? mandat.piecesAFournir?.every((piece: PiecesAFournir) => piece.docExist) : true;

    })
  }

  openPDFNewTab(mandat: MandatSepa) {
    this.loadingPreview = true;
    this.idSelectedMandatToPrint = mandat.idSEPAMandat;
    this.mandatSepaService.getPdfMandatSepa(this.idFamille, mandat.idSEPAMandat).subscribe((result: any) => {
      let url = result.pdf;
      this.downloadPdfFromBase64(url)
    });
  }

  downloadPdfFromBase64(base64String) {
    const binaryString = atob(base64String);
    const uint8Array = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    this.loadingPreview = false;
    window.open(url, '_blank');
  }

  onFileSelect(fileList: FileList, piece: PiecesAFournir, mandat: MandatSepa) {
    if (fileList.length) {
      this.uploadingFile = fileList[0];
      this.errorMessage = this.gedService.checkDocuments(fileList, this.configGed, this.uploadingFile);
      this.openFileUploadDialog(piece, mandat);
    }
  }

  getExtension(fileName: string) {
    return this.gedService.getExtension(fileName);
  }

  getFileIcon(doc: GedDocument) {
    return this.gedService.getFileIcon(doc);
  }

  openFileUploadDialog(piece: PiecesAFournir, mandat: MandatSepa) {
    this.uploadDialogRef = this.matDialog.open(FileUploadDialogComponent, {
      data: {
        piece,
        mandat,
        uploadingFile: this.uploadingFile,
        errorMessage: this.errorMessage
      },
      maxWidth: 600,
      minWidth: 200,
    });

    this.platformService.adaptDialogToScreen(this.uploadDialogRef);

    // CANCEL
    this.uploadDialogRef.componentInstance.onCancel.pipe(
      takeUntil(this.uploadDialogRef.afterClosed())
    ).subscribe(result => {
      if (result) {
        this.uploadDialogRef.close();
      }
    })

    // SUBMIT
    this.uploadDialogRef.componentInstance.onSubmit.pipe(
      takeUntil(this.uploadDialogRef.afterClosed())
    ).subscribe(result => {
      if (result) {
        this.uploadDialogRef.close();
        this.mandatSepaService.getCurrentFamilyDocuments().subscribe((docs: GedDocument[]) => {
          this.documents = docs;
        });
      }
    });

    this.uploadDialogRef.afterClosed().subscribe(_ => {
      this.previewURL = null;
      this.errorMessage = null;
      this.inputFile.map(input => input.nativeElement.value = null);
    });
  }

  openDocDetails(doc: GedDocument, mandat: MandatSepa) {
    const piece = mandat.piecesAFournir?.find(p => p.id === doc.idPieceAFournir);

    const dialog = this.matDialog.open(DocDetailsComponent, {
      data: { doc, piece, mandat, documentDownload: this.documentDownload }
    });

    this.platformService.adaptDialogToScreen(dialog);

    dialog.afterClosed().subscribe(_ => {
      window.URL.revokeObjectURL(this.previewURL);
      this.previewURL = null;
    });
  }

  downloadDocument(doc: GedDocument) {
    this.gedService.downloadDocument(doc, this.documentDownload);
  }

  deleteDocument(doc: GedDocument) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      data: { message: `Voulez vous supprimer le fichier : ${doc.nomFichier} ?` },
      maxWidth: 500
    });

    this.platformService.adaptDialogToScreen(dialogRef);

    dialogRef.afterClosed().subscribe(closeCode => {

      if (closeCode) {
        this.gedService.delete(doc.idDocumentElectronique).subscribe(res => {
          this.snackbar.info('Document supprimé');

          // delete locally
          this.deleteLocalDoc(doc.idDocumentElectronique);

        });
      }
    });
  }

  deleteLocalDoc(id: number) {
    this.documents.forEach((x, index) => {
      if (x.idDocumentElectronique === id) {
        this.documents.splice(index, 1);
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
